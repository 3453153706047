import { FC, useState } from 'react';
import { useController, useFormContext, useWatch } from 'react-hook-form';
import { mask, MASK_CHARACTER } from 'utils/maskUtils';
import { FormTextFieldProps } from 'components/shared/forms/FormFieldTypes';
import { EhiTextField } from 'components/shared/forms/EhiTextField';

export type RHMaskedTextFieldProps = FormTextFieldProps & {
  exceptLast?: number;
  shouldValidateOnChange?: boolean;
};

/**
 *  Current functionality:
 *  - Mask initial value
 *  - If user backspaces masked value, clear field
 *  - New (typed) value will be visible
 *
 *  EHI Masking standards: https://confluence.ehi.com/display/ASP/Protected+Data+Masking+Rules++-+Version+1.0
 */
export const MaskedTextField: FC<RHMaskedTextFieldProps> = ({
  exceptLast = 4,
  shouldValidateOnChange = true,
  onChange,
  name,
  ...rest
}) => {
  const { setValue } = useFormContext();
  const { field, fieldState } = useController({ name });

  const watchedValue = useWatch({ name });
  const [shouldDisplayFull, setShouldDisplayFull] = useState<boolean>();

  const handleChange = (e: any) => {
    setShouldDisplayFull(true);
    setValue(name, e.currentTarget.value, { shouldValidate: shouldValidateOnChange });
    onChange?.(e);
  };

  const clearValue = () => {
    setShouldDisplayFull(true);
    setValue(name, '', { shouldValidate: shouldValidateOnChange });
  };

  const handleKeyDown = (e: any) => {
    // keyCode is deprecated so adding support for code as well
    // If the backspace on masked value, clear it out since it wouldn't be valid
    if ((e.code === 'Backspace' || e.keyCode === 8) && e.target.value.includes(MASK_CHARACTER)) {
      clearValue();
    }
  };

  return (
    <>
      <EhiTextField
        name={`${name}-display-only`}
        value={shouldDisplayFull ? watchedValue : mask(watchedValue, exceptLast)}
        onKeyDown={handleKeyDown}
        onChange={handleChange}
        error={!!fieldState.error?.message}
        helperText={<>{fieldState.error?.message}</>}
        {...rest}
      />
      <input type='hidden' {...field} />
    </>
  );
};
