import { createRef, FC, RefObject, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import {
  BranchLookupFields,
  BranchLookupValues,
} from 'components/flexFlow/whenAndWhere/branchLookup/BranchLookupTypes';
import { EhiDivider } from 'components/shared/ui/styles/Divider.styles';
import { BranchLookupSearch } from 'components/flexFlow/whenAndWhere/branchLookup/BranchLookupSearch';
import { BranchLookupFilters } from 'components/flexFlow/whenAndWhere/branchLookup/BranchLookupFilters';
import BranchCardList from 'components/flexFlow/whenAndWhere/branchLookup/BranchCardList';
import { StyledDialog } from 'components/flexFlow/whenAndWhere/branchLookup/BranchLookup.styles';
import { useTranslations } from 'components/shared/i18n';
import { useFetchBranches } from 'components/flexFlow/whenAndWhere/branchLookup/useFetchBranches';
import { useBranchInfoByUrnQuery, useLocationGroups, useLocationInfoQueries } from 'services/location/locationQueries';
import { BranchLookupCard } from 'components/shared/uiModels/branchLookup/branchLookupDataTypes';
import { DateTime } from 'luxon';
import { useAlert } from 'components/shared/alert/AlertContext';
import { logError } from 'components/shared/logger/splunkLogger';
import { NoResultsView } from 'components/shared/ui/noResultsView/NoResultsView';
import { Box } from '@mui/material';
import { VirtuosoHandle } from 'react-virtuoso';
import { DAILY_RENTAL, getBranchLookupInitialValues, getGroupOptions } from 'utils/branchLookupUtils';
import { useEffectWhen } from 'hooks/useEffectWhen';
import { ProgressOverlay } from 'components/shared/ui/spinner/ProgressOverlay';
import { useDateTimeFormater } from 'utils/routing/useDatetimeFormater';
import { OptionItem } from 'components/shared/forms/FormFieldTypes';

type BranchLookupModalProps = {
  open: boolean;
  pickupOrDropOffLocation: string;
  handleCancel: () => void;
  title: string;
  handleApply: (
    branchUrn: string,
    stationId: string,
    locationCurrentTime: DateTime | undefined,
    timezone?: string
  ) => void;
};

export const BranchLookupModal: FC<BranchLookupModalProps> = ({
  open,
  pickupOrDropOffLocation,
  handleCancel,
  title,
  handleApply,
}: BranchLookupModalProps) => {
  const { t } = useTranslations();
  const { showAlert } = useAlert();
  const { getLocalizedDateTime } = useDateTimeFormater();

  const initialValues = useMemo(() => {
    return getBranchLookupInitialValues(t);
  }, [t]);
  const formMethods = useForm({
    defaultValues: initialValues,
  });

  const [branchResults, setBranchResults] = useState<
    { branches: BranchLookupCard[]; searchInputText: string } | undefined
  >(undefined);
  const branchListRef: RefObject<VirtuosoHandle> | undefined = createRef();
  const [isMapView, setMapView] = useState<boolean>(false);
  const selectedBranch = formMethods.watch(BranchLookupFields.SelectedBranch);
  const { executeSearch, isLoading } = useFetchBranches(formMethods.getValues());
  const { isFetching: isFetchingBranchInfo, refetch: refetchBranchInfo } = useBranchInfoByUrnQuery(
    (selectedBranch as BranchLookupCard | undefined)?.branchUrn,
    false
  );

  // This is a ref to keep the group options up to date when changing country. If not used, the group drop down
  // becomes behind and shows previous countries list in the input.
  const groupsRef = useRef<OptionItem[]>([]);
  const { locationQueriesComplete, brands, groups: defaultGroups, branchCountryCode } = useLocationInfoQueries();
  const formCountry = formMethods.watch(BranchLookupFields.Country);
  const modifiedCountryCode: string = useMemo(() => {
    if (formCountry !== branchCountryCode) {
      return formCountry;
    }
    return branchCountryCode;
  }, [branchCountryCode, formCountry]);

  const { isFetching: isLocationGroupsLoading, refetch: refetchOptions } = useLocationGroups(
    modifiedCountryCode,
    DAILY_RENTAL,
    false
  );

  // This sets initial state once all the location queries are complete
  useEffectWhen(() => {
    formMethods.reset({
      ...initialValues,
      brandFilter: brands,
      groupsFilter: defaultGroups.map((option) => option.id),
      countrySelect: branchCountryCode,
    });
    groupsRef.current = defaultGroups;
  }, locationQueriesComplete);

  // Calls when reset button is pressed or user selects default country in drop down
  useEffectWhen(() => {
    formMethods.resetField(BranchLookupFields.Groups, { defaultValue: defaultGroups.map((option) => option.id) });
    groupsRef.current = defaultGroups;
  }, JSON.stringify(groupsRef.current) !== JSON.stringify(defaultGroups) && modifiedCountryCode === branchCountryCode);

  // Calls when non-default country is selected
  useEffect(() => {
    if (formMethods.formState.dirtyFields[BranchLookupFields.Country]) {
      refetchOptions().then((value) => {
        const groupOptions = getGroupOptions(value.data);
        formMethods.resetField(BranchLookupFields.Groups, { defaultValue: groupOptions.map((option) => option.id) });
        groupsRef.current = groupOptions;
      });
    }
  }, [formMethods, modifiedCountryCode, refetchOptions]);

  const toggleMapView = useCallback(
    (isMapView: boolean) => {
      setMapView(isMapView);
      branchListRef.current && branchListRef.current.scrollToIndex(0);
    },
    [branchListRef]
  );

  const handleSearch = useCallback(async () => {
    branchListRef.current && branchListRef.current.scrollToIndex(0);
    const branches = await executeSearch();
    setBranchResults({ branches, searchInputText: formMethods.getValues(BranchLookupFields.SearchInputValue) });
  }, [branchListRef, executeSearch, formMethods]);

  const onSubmit = useCallback(
    async (values: BranchLookupValues) => {
      const selectedBranch = values[BranchLookupFields.SelectedBranch];
      if (selectedBranch?.branchUrn && selectedBranch.stationId) {
        try {
          const { data: branchInfo, error } = await refetchBranchInfo();
          if (error) {
            await showAlert({ responseMessages: error?.errors });
          } else {
            handleApply(
              selectedBranch?.branchUrn,
              selectedBranch?.stationId,
              getLocalizedDateTime(branchInfo?.timezone ?? '', DateTime.now()),
              branchInfo?.timezone ?? undefined
            );
          }
        } catch (error) {
          logError({ message: `No branch info details for ${selectedBranch?.branchUrn}` });
          handleApply(
            selectedBranch?.branchUrn,
            selectedBranch?.stationId,
            undefined, // getLocalizedDateTime('', DateTime.now()),
            undefined
          );
        }
      } else {
        await showAlert({
          title: t('error.error'),
          description: t('whenWhere.branchError'),
        });
      }
    },
    [getLocalizedDateTime, handleApply, refetchBranchInfo, showAlert, t]
  );

  const handleReset = useCallback(() => {
    formMethods.reset();
    setBranchResults(undefined);
    setMapView(false);
  }, [formMethods]);

  const hasNoResults = branchResults?.branches?.length === 0;

  return (
    <FormProvider {...formMethods}>
      <StyledDialog
        PaperProps={{
          sx: {
            height: '94%',
            width: '96%',
          },
        }}
        data-testid={`branch-lookup-modal-${pickupOrDropOffLocation}`}
        id={'branch-lookup-dialog'}
        contentPadding={0}
        open={open}
        title={title}
        a11yKey='content'
        fullScreen
        showDividers={true}
        actions={{
          secondaryAction: {
            label: t('common.cancel'),
            onClick: handleCancel,
          },
          primaryAction: {
            label: t('common.apply'),
            onClick: formMethods.handleSubmit(onSubmit),
            overrideButtonProps: { disabled: !selectedBranch },
          },
        }}>
        <BranchLookupSearch groupOptions={groupsRef.current} onSearch={handleSearch} handleReset={handleReset} />
        <EhiDivider />
        <BranchLookupFilters onMapViewToggle={toggleMapView} hasResults={!!branchResults} />
        <EhiDivider />
        {hasNoResults ? (
          <Box paddingTop={12}>
            <NoResultsView
              noResultsTitle='whenWhere.noResultsTitle'
              noResultsDescription={t('whenWhere.noResultsDescription')}
              isBackgroundColor={false}
              pageTitle={true}
            />
          </Box>
        ) : (
          <BranchCardList
            branches={branchResults?.branches ?? []}
            searchInputText={branchResults?.searchInputText ?? ''}
            branchListRef={branchListRef}
            isMapView={isMapView}
          />
        )}
        <ProgressOverlay
          inProgress={isLoading || isFetchingBranchInfo || !locationQueriesComplete || isLocationGroupsLoading}
        />
      </StyledDialog>
    </FormProvider>
  );
};
