import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { selectBookingEditorId } from 'redux/selectors/bookingEditor';
import { useCallback } from 'react';
import { retrieveEditor } from 'services/booking/bookingService';
import { setReservation } from 'redux/slices/booking/bookingEditorSlice';
import { safelyCatchError } from 'utils/errorUtils';
import { ReservationEditor } from 'services/booking/bookingTypes';
import { ServiceResultType } from 'services/types/ServiceResultTypes';
import { useAlert } from 'components/shared/alert/AlertContext';
import { useTranslations } from 'components/shared/i18n';
import { useNavigate } from 'react-router-dom';
import { useReservationSessionHelper } from 'components/shared/preprocessor/useReservationSessionHelper';
import { SelectedAction } from 'components/shared/alert/AlertDialogTypes';
import { RouterPaths } from 'app/router/RouterPaths';
import { useResSnackbarContext } from 'context/resSnackbar/ResSnackbarContext';

export type RefreshEditorHook = {
  /**
   *  Retrieves Reservation Editor based on current session editorId, and updates redux with the updated editor.
   *   (returning service result so save and navigate works appropriately, don't want to continue to navigate on errors)
   *
   *  @return {Promise<ServiceResultType<ReservationEditor>>}
   */
  refreshEditor: (editorId?: string, snackbarFilter?: string[]) => Promise<ServiceResultType<ReservationEditor>>;
};

export type EhiHeaderMessagesType = {
  code: string;
  severity: string;
  supportInformation: string;
  localizedMessage: string;
};

export const useRefreshEditor = (): RefreshEditorHook => {
  const { t } = useTranslations();
  const bookingEditorId = useAppSelector(selectBookingEditorId);
  const dispatch = useAppDispatch();
  const { showAlert } = useAlert();
  const navigate = useNavigate();
  const { clearEditorSession } = useReservationSessionHelper();
  const { setSnackBarRes } = useResSnackbarContext();

  const refreshEditor = useCallback(
    async (
      editorId = bookingEditorId,
      snackbarFilter: string[] = []
    ): Promise<ServiceResultType<ReservationEditor>> => {
      let editor;
      let errors;
      try {
        editor = await retrieveEditor(editorId);

        if (editor?.ehiMessages) {
          const parsedWarningMessages: EhiHeaderMessagesType[] = JSON.parse(atob(editor?.ehiMessages));
          const filteredMessages = parsedWarningMessages.filter((message) => !snackbarFilter.includes(message.code));

          if (filteredMessages.length > 0) {
            setSnackBarRes({
              message: filteredMessages.map((message) => message.supportInformation)?.toString(),
              isOpen: true,
            });
          }
        }
      } catch (error) {
        const ehiErrorsResponse = safelyCatchError(error);
        errors = ehiErrorsResponse.errors;

        const selection = await showAlert({
          variant: 'error',
          description: t('error.editorError', {
            error: errors?.[0].localizedMessage || '',
          }),
          primaryActionText: t('error.reload'),
          secondaryActionText: t('error.home'),
        });

        if (selection === SelectedAction.Primary) {
          return refreshEditor(editorId);
        } else if (selection === SelectedAction.Secondary) {
          clearEditorSession();
          navigate(RouterPaths.Search, {
            replace: true,
          });
          // Note: React Router's navigation is asynchronous. This means that the navigation process starts,
          // but the code continues to execute without waiting for the navigation to finish.
          // be sure to send back errors to prevent unwanted behavior
          return { errors: undefined };
        }
      }

      if (editor) {
        dispatch(setReservation({ editor: editor.data }));
      }

      return { data: editor?.data as ReservationEditor, success: !!editor, errors };
    },
    [bookingEditorId, clearEditorSession, dispatch, navigate, showAlert, t, setSnackBarRes]
  );

  return { refreshEditor };
};
