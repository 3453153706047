import { Helmet } from 'react-helmet-async';
import { useTranslations } from 'components/shared/i18n/useTranslations';
import { DynamicHelmetProps } from './DynamicHelmetTypes';
import { FC } from 'react';

const DynamicHelmet: FC<DynamicHelmetProps> = ({ title, description }) => {
  const { t } = useTranslations();
  return (
    <Helmet>
      <title>
        {title}
        {description || t('common.tabPrefixGreenLight')}
      </title>
    </Helmet>
  );
};
export default DynamicHelmet;
