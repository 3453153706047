import { FC } from 'react';
import { MoreMenu, MoreMenuItem } from '@ehi/ui';

export interface MenuItem {
  onClick: () => void;
  title: string;
  disabled: boolean;
}

interface MenuProps {
  onClose?: () => void;
  disabled?: boolean;
  menuItems?: MenuItem[];
}

export const Menu: FC<MenuProps> = ({ onClose, disabled, menuItems }: MenuProps) => {
  return (
    <MoreMenu
      data-testid={'more-menu'}
      disabled={disabled}
      menuItems={menuItems?.map(({ onClick, title, disabled }, index) => (
        <MoreMenuItem
          onClick={() => {
            onClick();
            onClose?.();
          }}
          key={`menu-item-${index}`}
          disabled={disabled}>
          {title}
        </MoreMenuItem>
      ))}
    />
  );
};
