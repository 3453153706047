import { FlexiFunctionComponent } from 'components/shared/flexiFlow/FlexFlowTypes';
import { FlexFlowCard, FlexiFlowCardInnerContainer } from 'components/shared/ui/card/Card';
import { StickyCardNavigation } from 'components/shared/ui/card/StickyCardNavigation';
import CardContent from '@mui/material/CardContent';
import { Caption2 } from 'components/shared/ui/styles/Typography.styles';
import { Body1, Body2, ehiTheme, H6, Subtitle1 } from '@ehi/ui';
import { useTranslations } from 'components/shared/i18n';
import { mapLoadingState } from 'components/shared/ui/spinner/loadableView/LoadableViewUtils';
import { selectIsReadOnlyFlow, selectPayers, selectPickup } from 'redux/selectors/bookingEditor';
import { useAppSelector } from 'redux/hooks';
import { parseUrn, parseUrnItem } from 'utils/urnUtils';
import { useMemo, useState } from 'react';
import { SavingFormProvider } from 'context/saveAction/SavingFormProvider';
import { useForm } from 'react-hook-form';
import { PaymentFields, PaymentValues } from 'components/flexFlow/payment/PaymentTypes';
import { useSavePayment } from 'components/flexFlow/payment/useSavePayment';
import { ProgressOverlay } from 'components/shared/ui/spinner/ProgressOverlay';
import { SelectMenu } from 'components/shared/forms/SelectMenu';
import { DOUBLE_DASH, EMPTY_VALUE } from 'utils/constants';
import { getPaymentTypeOptions } from 'utils/paymentUtils';
import { EhiDivider } from 'components/shared/ui/styles/Divider.styles';
import { Box } from '@mui/system';
import { BillToItem, BillToItemsContainer, PaymentSectionBox } from 'components/flexFlow/payment/Payment.styles';
import { useBusinessAccountQuery } from 'services/businessAccount/accountQueries';
import { BillingAccountPayer, EditorBusinessPayer } from 'services/booking/bookingTypes';
import { useRetrieveBranchEffectivePolicies } from 'services/location/locationQueries';
import { BillingAccountType } from 'components/flexFlow/rateAndBilling/editDialogs/billTo/EditBillToDialogTypes';
import { PaymentDetails } from 'components/flexFlow/payment/PaymentDetails';
import { useGetRenterProfile } from 'services/renter/useGetRenterProfile';
import { useCardBrandsQuery } from 'services/payment/paymentReferenceQueries';
import { OptionItem } from 'components/shared/forms/FormFieldTypes';

export const Payment: FlexiFunctionComponent = ({ onNext, onPrevious }) => {
  const { t } = useTranslations();
  const { save } = useSavePayment();
  const readOnly = useAppSelector(selectIsReadOnlyFlow);
  const payers = useAppSelector(selectPayers);
  const [loading, setLoading] = useState(false);
  const pickupData = useAppSelector(selectPickup);
  const { data: policies } = useRetrieveBranchEffectivePolicies(parseUrn(pickupData?.branch));
  const { data: cardBrands } = useCardBrandsQuery();
  const { driverData, isRenterProfileLoading } = useGetRenterProfile();

  const paymentOptions = useMemo((): OptionItem[] => {
    return getPaymentTypeOptions(t, policies);
  }, [policies, t]);

  const billingAccount = useMemo(() => {
    const businessPayer: BillingAccountPayer | undefined = payers?.business?.find(
      (businessPayer: EditorBusinessPayer) => businessPayer.type === BillingAccountType.BILLING_ACCOUNT
    ) as BillingAccountPayer;
    return businessPayer?.billingAccount;
  }, [payers?.business]);

  const { data: billToAccount } = useBusinessAccountQuery(parseUrnItem(billingAccount, 'account'));

  const formMethods = useForm<PaymentValues>({
    defaultValues: {
      paymentType: parseUrn(payers?.person?.paymentMethod) ?? EMPTY_VALUE,
    },
    resolver: undefined,
  });

  const selectedPaymentType = formMethods.getValues(PaymentFields.PaymentType);

  const selectedValue = useMemo((): OptionItem | '' => {
    return paymentOptions.find((value) => value?.id === selectedPaymentType) ?? EMPTY_VALUE;
  }, [selectedPaymentType, paymentOptions]);

  const showPaymentDetails = !!selectedValue && !isRenterProfileLoading && driverData && !readOnly;

  const handleSubmit = async (values: PaymentValues) => {
    setLoading(true);
    return save(values).finally(() => {
      setLoading(false);
    });
  };

  return (
    <FlexFlowCard loadingState={mapLoadingState(false, false)}>
      <StickyCardNavigation onPrevious={onPrevious} onNext={onNext} showExitToRentalSummary={true} />
      <SavingFormProvider {...formMethods} submitFn={(values: PaymentValues) => handleSubmit(values)}>
        <CardContent>
          <FlexiFlowCardInnerContainer style={{ padding: 0, marginTop: ehiTheme.spacing(1) }}>
            {billToAccount && (
              <>
                <PaymentSectionBox data-testid='billToSection'>
                  <Box display={'flex'} justifyContent={'space-between'}>
                    <H6>{t('rateAndBilling.billTo.pageTitle')}</H6>
                  </Box>
                  <BillToItemsContainer>
                    <BillToItem>
                      <Subtitle1 bold>{billToAccount?.name}</Subtitle1>
                      <Body2>{t('payment.directBill')}</Body2>
                    </BillToItem>
                  </BillToItemsContainer>
                </PaymentSectionBox>
                <EhiDivider />
              </>
            )}
            <PaymentSectionBox>
              <Box display={'flex'} justifyContent={'space-between'}>
                <H6>{t('common.renter')}</H6>
              </Box>
              <Box my={3}>
                {readOnly ? (
                  <>
                    <Caption2>{t('payment.paymentMethod')}</Caption2>
                    <Body1>{selectedValue && selectedValue?.label ? selectedValue?.label : DOUBLE_DASH}</Body1>
                  </>
                ) : (
                  <SelectMenu
                    formFieldName={PaymentFields.PaymentType}
                    label={t('payment.paymentMethod')}
                    data-testid='paymentDropDown'
                    primaryList={{
                      list: paymentOptions,
                    }}
                    textColor={'#4D789D'}
                  />
                )}
              </Box>
              {showPaymentDetails && (
                <PaymentDetails selectedValue={selectedValue} driverData={driverData} cardBrands={cardBrands} />
              )}
            </PaymentSectionBox>
            <ProgressOverlay inProgress={loading} />
          </FlexiFlowCardInnerContainer>
        </CardContent>
      </SavingFormProvider>
    </FlexFlowCard>
  );
};
