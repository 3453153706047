import { Dispatch, FC, SetStateAction, useCallback, useRef, useState } from 'react';
import { TabbedDialog, TabOption } from 'components/shared/ui/dialogs/TabbedDialog';
import { DriverSearch } from 'components/flexFlow/driver/driverSearch/DriverSearch';
import { useTranslations } from 'components/shared/i18n';
import { DriverForm } from 'components/flexFlow/driver/driverForm/DriverForm';
import { DriverData } from 'components/shared/uiModels/driver/driverDataTypes';

export type AddDriverDialogProps = {
  open: boolean;
  onClose: () => void;
  title: string;
  addDriver?: (driver: DriverData) => void;
  isUpdatingProfile?: boolean;
  changePrimaryDriverDialogIsOpen: boolean;
  setChangePrimaryDriverDialogIsOpen: Dispatch<SetStateAction<boolean>>;
};

export enum AddDriverTabKeys {
  addDriverTab,
  createDriverTab,
}

export const AddDriverDialog: FC<AddDriverDialogProps> = ({
  open,
  onClose,
  title,
  isUpdatingProfile = false,
  changePrimaryDriverDialogIsOpen,
  setChangePrimaryDriverDialogIsOpen,
}) => {
  const { t } = useTranslations();
  const formRef = useRef<{ handleSubmit: () => Promise<void> }>(null);
  const [selectedTab, setSelectedTab] = useState<AddDriverTabKeys>(AddDriverTabKeys.addDriverTab);
  // We need to use this to pass renter information form values to the driver form, when search results are empty.
  const [driverData, setDriverData] = useState<DriverData | undefined>(undefined);

  const submitForm = async () => {
    if (formRef.current) {
      await formRef.current?.handleSubmit();
    }
  };

  const handleClose = useCallback(() => {
    setSelectedTab(AddDriverTabKeys.addDriverTab);
    setDriverData(undefined);
    onClose();
  }, [onClose]);

  const tabOptions: TabOption[] = [
    {
      label: t('driver.searchDrivers'),
      content: (
        <DriverSearch
          onClose={handleClose}
          navigateToDriverForm={(values: DriverData | undefined) => {
            setDriverData(values);
            setSelectedTab(AddDriverTabKeys.createDriverTab);
          }}
          isUpdatingPrimaryDriver={isUpdatingProfile}
        />
      ),
      actions: {
        secondaryAction: {
          label: t('common.cancel'),
          onClick: handleClose,
        },
      },
    },
    {
      label: t('driver.createNewDriver'),
      content: (
        <DriverForm
          onClose={handleClose}
          formRef={formRef}
          driver={driverData}
          isUpdatingPrimaryDriver={isUpdatingProfile}
          changePrimaryDriverDialogIsOpen={changePrimaryDriverDialogIsOpen}
          setChangePrimaryDriverDialogIsOpen={setChangePrimaryDriverDialogIsOpen}
        />
      ),
      actions: {
        primaryAction: {
          label: t('driverSearch.add'),
          onClick: submitForm,
        },
        secondaryAction: {
          label: t('common.cancel'),
          onClick: handleClose,
        },
      },
    },
  ];

  return (
    <>
      <TabbedDialog
        title={title}
        open={open}
        tabOptions={tabOptions}
        onClose={handleClose}
        selectedTab={selectedTab}
        onTabChange={(selected) => {
          setSelectedTab(selected);
        }}
        contentPadding={0}
        showDividers={true}
        data-testId={'addDriverDialog'}
      />
    </>
  );
};
