import { RateSource, Renter } from 'services/booking/bookingTypes';
import { retrieveBusinessAccount } from 'services/businessAccount/businessAccountService';
import { ReservationAccount } from 'services/businessAccount/businessAccountTypes';
import { getDriverProfileLoyaltyMembership } from 'services/renter/driverProfile/driverProfileService';
import { SearchResult } from 'services/renter/driverProfile/driverProfileTypes';
import { parseUrn } from 'utils/urnUtils';
import { LoyaltyProgram, Brand } from 'utils/constants';

export const checkLoyaltyDriverWithRateSource = async (
  driverData: SearchResult,
  handleLogError: (error: any, message: string) => void,
  setLoading: (isLoading: boolean) => void
): Promise<ReservationAccount | null> => {
  let accountDetails: ReservationAccount | null = null;

  try {
    const membershipProgram = driverData?.loyaltyMembership?.loyaltyProgram;
    if (membershipProgram && driverData.urn) {
      const membership = await getDriverProfileLoyaltyMembership(parseUrn(driverData.urn), parseUrn(membershipProgram));

      const alternateContractId = membership?.rateContracts?.[0]?.alternateContractId;
      if (alternateContractId) {
        accountDetails = await retrieveBusinessAccount(alternateContractId);
      }
    }
  } catch (error) {
    handleLogError(error, fetchLoyaltyMembershipError);
    setLoading(false);
    return null;
  }

  return accountDetails;
};

export const fetchLoyaltyMembershipError = 'fetch loyalty membership error';
export const associateRenterToReservationError = 'associate renter to reservation error';
export const addAdditionalDriverError = 'add additional driver error';
export const addDriverError = 'Unable to add driver';

export const DRIVER_PROFILE = 'DRIVER_PROFILE';
export const NEGOTIATED = 'NEGOTIATED';
export const LOYALTY = 'loyalty';
export const CURRENT = 'current';
export const BOOK2077 = 'BOOK2077';
export const PRIMARY_DRIVER = 'primaryDriver';
export const RATE_SOURCE = 'rateSource';

export const createRequestBody = (
  requestType: typeof DRIVER_PROFILE | typeof NEGOTIATED,
  profileUrn: string | undefined,
  membership: string | undefined,
  accountDetails?: string | undefined
) => {
  if (requestType === DRIVER_PROFILE) {
    return {
      type: DRIVER_PROFILE,
      profile: profileUrn,
      membership: membership ? { loyaltyProgram: membership } : undefined,
    } as Renter;
  } else if (requestType === NEGOTIATED) {
    return {
      type: NEGOTIATED,
      account: accountDetails ?? '',
    } as RateSource;
  }
};

export const prioritizeByBrand = (profiles: SearchResult[], rentalBrand: string | undefined): SearchResult[] => {
  const loyaltyProfiles: SearchResult[] = [];
  const nonLoyaltyProfiles: SearchResult[] = [];

  profiles.forEach((profile) => {
    const loyaltyType = profile.loyaltyMembership?.type;

    if (loyaltyType) {
      if (
        (rentalBrand === Brand.ENTERPRISE &&
          (loyaltyType === LoyaltyProgram.ENTERPRISE_PLUS || loyaltyType === LoyaltyProgram.EMERALD_CLUB)) ||
        (rentalBrand === Brand.NATIONAL && loyaltyType === LoyaltyProgram.EMERALD_CLUB) ||
        (rentalBrand === Brand.ALAMO && loyaltyType === LoyaltyProgram.ALAMO_INSIDERS) ||
        !rentalBrand
      ) {
        loyaltyProfiles.push(profile);
      }
    } else {
      nonLoyaltyProfiles.push(profile);
    }
  });

  return [...loyaltyProfiles, ...nonLoyaltyProfiles];
};
