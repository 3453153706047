import { styled } from '@mui/material';
import { FlexiFlowCardInnerContainer } from 'components/shared/ui/card/Card';

export const RentalCard = styled(FlexiFlowCardInnerContainer)`
  &.dateTimeRentalLocation {
    min-height: 36vh;
    @media (max-width: 700px) {
      min-height: 42vh;
    }
  }

  &.dateTimeError {
    min-height: 26vh;
    @media (max-width: 1000px) {
      min-height: 30vh;
    }
  }

  &.rentalLocation {
    min-height: 28vh;
    @media (max-width: 800px) {
      min-height: 32vh;
    }
  }
`;
