import { createContext, Dispatch, FC, ReactNode, SetStateAction, useContext, useState } from 'react';
import { ReservationSnackBar, SnackbarProps } from 'context/resSnackbar/ReservationSnackBar';
import { EMPTY_VALUE } from 'utils/constants';

export type ResSnackbarContextType = {
  setSnackBarRes: Dispatch<SetStateAction<SnackbarProps>>;
};

export const ResSnackbarContext = createContext<ResSnackbarContextType>({
  setSnackBarRes: () => ({ message: EMPTY_VALUE, isOpen: false }),
});

export const useResSnackbarContext = () => {
  const context = useContext(ResSnackbarContext);

  if (!context) {
    throw Error('ResSnackbarContext is not initialized');
  }

  return context;
};

type ResSnackbarProviderProps = {
  children?: ReactNode;
};

export const ResSnackbarProvider: FC<ResSnackbarProviderProps> = ({ children }) => {
  const [snackBarRes, setSnackBarRes] = useState<SnackbarProps>({
    message: EMPTY_VALUE,
    isOpen: false,
  });

  return (
    <ResSnackbarContext.Provider value={{ setSnackBarRes }}>
      {children}
      <ReservationSnackBar {...snackBarRes} />
    </ResSnackbarContext.Provider>
  );
};
