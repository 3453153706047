import { AdditionalInfoLabel, AdditionalVehicleInfoContainer } from 'components/flexFlow/vehicle/VehiclePage.styles';
import { Box, Grid } from '@mui/material';
import { FC, useCallback, JSX } from 'react';
import { useLocale, useTranslations } from 'components/shared/i18n';
import { Caption, ehiTheme } from '@ehi/ui';
import { VehicleCardProps } from 'components/flexFlow/vehicle/VehicleTypes';
import { PeriodicDistance, PeriodicVehicleRate, PotentiallyAdjustableAmount } from 'services/booking/bookingTypes';
import { formatCurrency } from 'utils/currencyUtils';
import { getTotalDistance } from 'utils/vehicleUtils';
import { VehicleFeatures } from 'components/flexFlow/vehicle/VehicleFeatures';

export const AdditionalVehicleInfo: FC<VehicleCardProps> = ({ vehicle, isSelected }) => {
  const { t } = useTranslations();
  const periodicRate = vehicle.vehicleRates?.paylater?.vehicleRate as PeriodicVehicleRate;
  const { locale } = useLocale();

  const getPeriodicRates = useCallback(() => {
    const gridItems = new Array<JSX.Element>();
    if (periodicRate) {
      const costPerDay = periodicRate?.costPerDay || periodicRate?.extraTimeRate?.costPerDay;
      const costPerWeek = periodicRate?.costPerWeek;
      const costPerMonth = periodicRate?.costPerMonth;
      const costPerHour = periodicRate?.costPerHour;

      if (costPerDay) {
        gridItems.push(
          <Grid item key={'dailyRate'}>
            <Caption>
              {formatCurrency(costPerDay?.amount ?? 0, costPerDay?.currencyCode ?? '', locale) + t('rates.perDay')}
            </Caption>
          </Grid>
        );
      }
      if (costPerWeek) {
        gridItems.push(
          <Grid item key={'weeklyRate'}>
            <Caption>
              {formatCurrency(costPerWeek?.amount ?? 0, costPerWeek?.currencyCode ?? '', locale) + t('rates.perWeek')}
            </Caption>
          </Grid>
        );
      }
      if (costPerMonth) {
        gridItems.push(
          <Grid item key={'monthlyRate'}>
            <Caption>
              {formatCurrency(costPerMonth?.amount ?? 0, costPerMonth?.currencyCode ?? '', locale) +
                t('rates.perMonth')}
            </Caption>
          </Grid>
        );
      }

      if (costPerHour) {
        gridItems.push(
          <Grid item key={'hourlyRate'}>
            <Caption>
              {formatCurrency(costPerHour?.amount ?? 0, costPerHour?.currencyCode ?? '', locale) + t('rates.perHour')}
            </Caption>
          </Grid>
        );
      }
    }

    return (
      <Grid container display={'flex'} flexDirection={'column'}>
        {gridItems}
      </Grid>
    );
  }, [locale, periodicRate, t]);

  const getDistanceAllotment = useCallback(() => {
    const includedDistanceRates: PeriodicDistance | undefined = periodicRate?.includedDistance;
    const amountPerExcessDistance: PotentiallyAdjustableAmount | undefined = periodicRate?.amountPerExcessDistance;

    if (periodicRate?.totalIncludedDistance?.value && periodicRate?.totalIncludedDistance?.value > 0) {
      return (
        <>
          {periodicRate?.totalIncludedDistance?.value && (
            <Caption data-testid={'totalIncludedDistance'}>
              {periodicRate?.totalIncludedDistance?.value + t('rates.perRental')}
            </Caption>
          )}
          {amountPerExcessDistance && amountPerExcessDistance.amount && (
            <Caption data-testid={'amountPerExcessDistance'}>{`${formatCurrency(
              amountPerExcessDistance.amount,
              amountPerExcessDistance.currencyCode ?? '',
              locale
            )}${t('rates.perExcess')}`}</Caption>
          )}
        </>
      );
    } else if (includedDistanceRates && getTotalDistance(includedDistanceRates) > 0) {
      const gridItems = new Array<JSX.Element>();
      if (includedDistanceRates.distancePerDay && includedDistanceRates.distancePerDay > 0) {
        gridItems.push(
          <Grid item key={'distancePerDay'}>
            <Caption data-testid={'distancePerDay'}>{includedDistanceRates.distancePerDay + t('rates.perDay')}</Caption>
          </Grid>
        );
      }
      if (includedDistanceRates.distancePerWeek && includedDistanceRates.distancePerWeek > 0) {
        gridItems.push(
          <Grid item key={'distancePerWeek'}>
            <Caption data-testid={'distancePerWeek'}>
              {includedDistanceRates.distancePerWeek + t('rates.perWeek')}
            </Caption>
          </Grid>
        );
      }
      if (includedDistanceRates.distancePerMonth && includedDistanceRates.distancePerMonth > 0) {
        gridItems.push(
          <Grid item key={'distancePerMonth'}>
            <Caption data-testid={'distancePerMonth'}>
              {includedDistanceRates.distancePerMonth + t('rates.perMonth')}
            </Caption>
          </Grid>
        );
      }
      return (
        <Grid container display={'flex'} flexDirection={'column'}>
          {gridItems}
          {amountPerExcessDistance && amountPerExcessDistance.amount && (
            <Caption data-testid={'amountPerExcessDistance'}>{`${formatCurrency(
              amountPerExcessDistance.amount,
              amountPerExcessDistance.currencyCode ?? '',
              locale
            )}${t('rates.perExcess')}`}</Caption>
          )}
        </Grid>
      );
    } else {
      return <Caption data-testid={'vehicleUnlimitedMileageLabel'}>{t('vehicle.unlimitedMileage')}</Caption>;
    }
  }, [
    locale,
    periodicRate?.amountPerExcessDistance,
    periodicRate?.includedDistance,
    periodicRate?.totalIncludedDistance,
    t,
  ]);

  return (
    <AdditionalVehicleInfoContainer data-testid={'vehicleAdditionalInfo'} selected={isSelected}>
      {vehicle.vehicleRates ? (
        <Grid container spacing={2} alignItems={'flex-start'}>
          <Grid item xs={12} sm={8} md={8}>
            <Grid container>
              <Grid item xs={4} sm={4} md={3} display={'flex'} paddingRight={ehiTheme.spacing(1)}>
                <img src={vehicle.imageKey} alt={''} height={'100%'} width={'100%'} />
              </Grid>
              <Grid item xs={8} sm={8} md={9} data-testid={'vehicleFeaturesSection'}>
                <VehicleFeatures vehicle={vehicle} />
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={3} sm={2} md={2} data-testid={'vehicleRate'}>
            <AdditionalInfoLabel>{t('vehicle.vehicleRate')}</AdditionalInfoLabel>
            <Grid container display={'flex'} flexDirection={'column'}>
              {getPeriodicRates()}
            </Grid>
          </Grid>
          <Grid item xs={3} sm={2} md={2} data-testid={'distanceAllotment'}>
            <AdditionalInfoLabel>{t('vehicle.distanceAllotment')}</AdditionalInfoLabel>
            <Grid container display={'flex'} flexDirection={'column'}>
              {getDistanceAllotment()}
            </Grid>
          </Grid>
        </Grid>
      ) : (
        <Box>
          <Grid container>
            <Grid item xs={4} sm={4} md={3} display={'flex'} justifyContent={'end'} paddingRight={ehiTheme.spacing(1)}>
              <img src={vehicle.imageKey} alt={''} height={'100%'} width={'100%'} />
            </Grid>
            <Grid item xs={8} sm={8} md={9} data-testid={'vehicleFeaturesSection'}>
              <VehicleFeatures vehicle={vehicle} />
            </Grid>
          </Grid>
        </Box>
      )}
    </AdditionalVehicleInfoContainer>
  );
};
