import { useTranslation } from 'react-i18next';
import { TFunction } from 'i18next';
import i18n from './i18n';

export const useTranslations = (): {
  t: TFunction<'translation'>;
} => {
  const { t } = useTranslation('translation', { i18n });
  return { t };
};
