export enum SearchByType {
  AccountNumber = 'accountNumber',
  AccountSearch = 'accountSearch',
}

export enum BillToFormFields {
  AccountNumber = 'accountNumber',
  BillingNumber = 'billingNumber',
  HiddenAccountNumber = 'hiddenAccountNumber',
}

export interface BillToFormValues {
  [BillToFormFields.AccountNumber]: string;
  [BillToFormFields.BillingNumber]: string;
  [BillToFormFields.HiddenAccountNumber]: string;
}

export const enum BillingAccountType {
  BILLING_ACCOUNT = 'BILLING_ACCOUNT',
  BILL_TO = 'BILL_TO',
}
