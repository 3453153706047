/*
This file contains the list of possible booking issues as there isn't any domain service currently.
 */

import { TranslationKey } from 'components/shared/i18n/i18n';

export type BookingIssueType = {
  code: string;
  translationKey: TranslationKey;
};

export const bookingIssues: BookingIssueType[] = [
  {
    code: 'BOOK1000',
    translationKey: 'bookingIssue.book1000',
  },
  {
    code: 'BOOK1001',
    translationKey: 'bookingIssue.book1001',
  },
  {
    code: 'BOOK1002',
    translationKey: 'bookingIssue.book1002',
  },
  {
    code: 'BOOK1003',
    translationKey: 'bookingIssue.book1003',
  },
  {
    code: 'BOOK1004',
    translationKey: 'bookingIssue.book1004',
  },
  {
    code: 'BOOK1005',
    translationKey: 'bookingIssue.book1005',
  },
  {
    code: 'BOOK1006',
    translationKey: 'bookingIssue.book1006',
  },
  {
    code: 'BOOK1007',
    translationKey: 'bookingIssue.book1007',
  },
  {
    code: 'BOOK1008',
    translationKey: 'bookingIssue.book1008',
  },
  {
    code: 'BOOK1009',
    translationKey: 'bookingIssue.book1009',
  },
  {
    code: 'BOOK1011',
    translationKey: 'bookingIssue.book1011',
  },
  {
    code: 'BOOK1012',
    translationKey: 'bookingIssue.book1012',
  },
  {
    code: 'BOOK1013',
    translationKey: 'bookingIssue.book1013',
  },
  {
    code: 'BOOK1014',
    translationKey: 'bookingIssue.book1014',
  },
  {
    code: 'BOOK1015',
    translationKey: 'bookingIssue.book1015',
  },
  {
    code: 'BOOK1016',
    translationKey: 'bookingIssue.book1016',
  },
  {
    code: 'BOOK1017',
    translationKey: 'bookingIssue.book1017',
  },
  {
    code: 'BOOK1018',
    translationKey: 'bookingIssue.book1018',
  },
  {
    code: 'BOOK1019',
    translationKey: 'bookingIssue.book1019',
  },
  {
    code: 'BOOK1020',
    translationKey: 'bookingIssue.book1020',
  },
  {
    code: 'BOOK1021',
    translationKey: 'bookingIssue.book1021',
  },
  {
    code: 'BOOK1022',
    translationKey: 'bookingIssue.book1022',
  },
  {
    code: 'BOOK1023',
    translationKey: 'bookingIssue.book1023',
  },
  {
    code: 'BOOK1025',
    translationKey: 'bookingIssue.book1025',
  },
  {
    code: 'BOOK1026',
    translationKey: 'bookingIssue.book1026',
  },
  {
    code: 'BOOK1027',
    translationKey: 'bookingIssue.book1027',
  },
  {
    code: 'BOOK1028',
    translationKey: 'bookingIssue.book1028',
  },
  {
    code: 'BOOK1029',
    translationKey: 'bookingIssue.book1029',
  },
  {
    code: 'BOOK1030',
    translationKey: 'bookingIssue.book1030',
  },
  {
    code: 'BOOK1031',
    translationKey: 'bookingIssue.book1031',
  },
  {
    code: 'BOOK1032',
    translationKey: 'bookingIssue.book1032',
  },
  {
    code: 'BOOK2000',
    translationKey: 'bookingIssue.book2000',
  },
  {
    code: 'BOOK2001',
    translationKey: 'bookingIssue.book2001',
  },
  {
    code: 'BOOK2002',
    translationKey: 'bookingIssue.book2002',
  },
  {
    code: 'BOOK2003',
    translationKey: 'bookingIssue.book2003',
  },
  {
    code: 'BOOK2004',
    translationKey: 'bookingIssue.book2004',
  },
  {
    code: 'BOOK2005',
    translationKey: 'bookingIssue.book2005',
  },
  {
    code: 'BOOK2006',
    translationKey: 'bookingIssue.book2006',
  },
  {
    code: 'BOOK2007',
    translationKey: 'bookingIssue.book2007',
  },
  {
    code: 'BOOK2008',
    translationKey: 'bookingIssue.book2008',
  },
  {
    code: 'BOOK2010',
    translationKey: 'bookingIssue.book2010',
  },
  {
    code: 'BOOK2011',
    translationKey: 'bookingIssue.book2011',
  },
  {
    code: 'BOOK2012',
    translationKey: 'bookingIssue.book2012',
  },
  {
    code: 'BOOK2013',
    translationKey: 'bookingIssue.book2013',
  },
  {
    code: 'BOOK2014',
    translationKey: 'bookingIssue.book2014',
  },
  {
    code: 'BOOK2015',
    translationKey: 'bookingIssue.book2015',
  },
  {
    code: 'BOOK2016',
    translationKey: 'bookingIssue.book2016',
  },
  {
    code: 'BOOK2017',
    translationKey: 'bookingIssue.book2017',
  },
  {
    code: 'BOOK2019',
    translationKey: 'bookingIssue.book2019',
  },
  {
    code: 'BOOK2021',
    translationKey: 'bookingIssue.book2021',
  },
  {
    code: 'BOOK2022',
    translationKey: 'bookingIssue.book2022',
  },
  {
    code: 'BOOK2023',
    translationKey: 'bookingIssue.book2023',
  },
  {
    code: 'BOOK2024',
    translationKey: 'bookingIssue.book2024',
  },
  {
    code: 'BOOK2025',
    translationKey: 'bookingIssue.book2025',
  },
  {
    code: 'BOOK2026',
    translationKey: 'bookingIssue.book2026',
  },
  {
    code: 'BOOK2027',
    translationKey: 'bookingIssue.book2027',
  },
  {
    code: 'BOOK2028',
    translationKey: 'bookingIssue.book2028',
  },
  {
    code: 'BOOK2029',
    translationKey: 'bookingIssue.book2029',
  },
  {
    code: 'BOOK2030',
    translationKey: 'bookingIssue.book2030',
  },
  {
    code: 'BOOK2031',
    translationKey: 'bookingIssue.book2031',
  },
  {
    code: 'BOOK2032',
    translationKey: 'bookingIssue.book2032',
  },
  {
    code: 'BOOK2033',
    translationKey: 'bookingIssue.book2033',
  },
  {
    code: 'BOOK2034',
    translationKey: 'bookingIssue.book2034',
  },
  {
    code: 'BOOK2035',
    translationKey: 'bookingIssue.book2035',
  },
  {
    code: 'BOOK2036',
    translationKey: 'bookingIssue.book2036',
  },
  {
    code: 'BOOK2037',
    translationKey: 'bookingIssue.book2037',
  },
  {
    code: 'BOOK2038',
    translationKey: 'bookingIssue.book2038',
  },
  {
    code: 'BOOK2039',
    translationKey: 'bookingIssue.book2039',
  },
  {
    code: 'BOOK2040',
    translationKey: 'bookingIssue.book2040',
  },
  {
    code: 'BOOK2041',
    translationKey: 'bookingIssue.book2041',
  },
  {
    code: 'BOOK2042',
    translationKey: 'bookingIssue.book2042',
  },
  {
    code: 'BOOK2043',
    translationKey: 'bookingIssue.book2043',
  },
  {
    code: 'BOOK2044',
    translationKey: 'bookingIssue.book2044',
  },
  {
    code: 'BOOK2045',
    translationKey: 'bookingIssue.book2045',
  },
  {
    code: 'BOOK2046',
    translationKey: 'bookingIssue.book2046',
  },
  {
    code: 'BOOK2047',
    translationKey: 'bookingIssue.book2047',
  },
  {
    code: 'BOOK2048',
    translationKey: 'bookingIssue.book2048',
  },
  {
    code: 'BOOK2049',
    translationKey: 'bookingIssue.book2049',
  },
  {
    code: 'BOOK2050',
    translationKey: 'bookingIssue.book2050',
  },
  {
    code: 'BOOK2051',
    translationKey: 'bookingIssue.book2051',
  },
  {
    code: 'BOOK2052',
    translationKey: 'bookingIssue.book2052',
  },
  {
    code: 'BOOK2053',
    translationKey: 'bookingIssue.book2053',
  },
  {
    code: 'BOOK2054',
    translationKey: 'bookingIssue.book2054',
  },
  {
    code: 'BOOK2055',
    translationKey: 'bookingIssue.book2055',
  },
  {
    code: 'BOOK2056',
    translationKey: 'bookingIssue.book2056',
  },
  {
    code: 'BOOK2057',
    translationKey: 'bookingIssue.book2057',
  },
  {
    code: 'BOOK2058',
    translationKey: 'bookingIssue.book2058',
  },
  {
    code: 'BOOK2059',
    translationKey: 'bookingIssue.book2059',
  },
  {
    code: 'BOOK2060',
    translationKey: 'bookingIssue.book2060',
  },
  {
    code: 'BOOK2061',
    translationKey: 'bookingIssue.book2061',
  },
  {
    code: 'BOOK2062',
    translationKey: 'bookingIssue.book2062',
  },
  {
    code: 'BOOK2063',
    translationKey: 'bookingIssue.book2063',
  },
  {
    code: 'BOOK2064',
    translationKey: 'bookingIssue.book2064',
  },
  {
    code: 'BOOK2065',
    translationKey: 'bookingIssue.book2065',
  },
  {
    code: 'BOOK2066',
    translationKey: 'bookingIssue.book2066',
  },
  {
    code: 'BOOK2067',
    translationKey: 'bookingIssue.book2067',
  },
  {
    code: 'BOOK2068',
    translationKey: 'bookingIssue.book2068',
  },
  {
    code: 'BOOK2069',
    translationKey: 'bookingIssue.book2069',
  },
  {
    code: 'BOOK2072',
    translationKey: 'bookingIssue.book2072',
  },
  {
    code: 'BOOK2078',
    translationKey: 'bookingIssue.book2078',
  },
  {
    code: 'BOOK3000',
    translationKey: 'bookingIssue.book3000',
  },
];
