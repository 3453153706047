import { FC } from 'react';

export const ErrorIcon: FC = () => {
  return (
    <svg width='80' height='80' viewBox='0 0 80 80' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M40.0003 6.66699C21.6003 6.66699 6.66699 21.6003 6.66699 40.0003C6.66699 58.4003 21.6003 73.3337 40.0003 73.3337C58.4003 73.3337 73.3337 58.4003 73.3337 40.0003C73.3337 21.6003 58.4003 6.66699 40.0003 6.66699ZM43.3337 56.667H36.667V50.0003H43.3337V56.667ZM43.3337 43.3337H36.667V23.3337H43.3337V43.3337Z'
        fill='#B00020'
      />
    </svg>
  );
};
