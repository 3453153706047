import { FC, useEffect, useMemo } from 'react';
import { Box, Grid } from '@mui/material';
import { AccountNumberSearchContainer, StyledFlexGrid } from 'components/flexFlow/rateAndBilling/RateAndBilling.styles';
import { Body1, Body2, EhiButton, ehiTheme } from '@ehi/ui';
import { FormTextField } from 'components/shared/forms/FormTextField';
import { InputIconButton } from 'components/shared/ui/InputIconButton/InputIconButton';
import { FieldClearIcon } from 'components/shared/ui/FieldClearIcon';
import { EMPTY_VALUE } from 'utils/constants';
import { useTranslations } from 'components/shared/i18n';
import {
  BillToFormFields,
  BillToFormValues,
} from 'components/flexFlow/rateAndBilling/editDialogs/billTo/EditBillToDialogTypes';
import { FormProvider, useForm, useFormContext } from 'react-hook-form';
import { useAlert } from 'components/shared/alert/AlertContext';
import {
  useAccountContactInfoQuery,
  useBillingAccountByAccountQuery,
  useBusinessAccountQuery,
} from 'services/businessAccount/accountQueries';
import { useBillTo } from 'components/flexFlow/rateAndBilling/editDialogs/billTo/useBillTo';
import { parseUrn } from 'utils/urnUtils';
import { useEffectWhen } from 'hooks/useEffectWhen';
import { HttpStatusCode } from 'services/types/EhiErrorsTypes';
import { GridContainer } from 'components/shared/ui/styles/Grid.styles';
import { MaskedTextField } from 'components/shared/forms/MaskedTextField';
import { ProgressOverlay } from 'components/shared/ui/spinner/ProgressOverlay';
import { transformAccountDetailsFromRetrieve } from 'utils/rateAndBillingUtils';
import { BusinessAccountCard } from 'components/flexFlow/rateAndBilling/editDialogs/BusinessAccountCard';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useEditBillToContext } from 'context/editBillTo/EditBillToContext';
import * as Yup from 'yup';
import { useYupValidationResolver } from 'components/shared/forms/useYupValidationResolver';
import { useAccountDetails } from 'services/businessAccount/useAccountDetails';

export type BillingAccountNumberSearchProps = {
  handleCloseModal: () => void;
  onBackClicked?: () => void;
};

export const BillToAccountNumberSearch: FC<BillingAccountNumberSearchProps> = ({ handleCloseModal, onBackClicked }) => {
  const { t } = useTranslations();
  const { addOrModifyBillTo } = useBillTo();
  const { isAccountNumberFieldHidden, selectedAccountUrn } = useEditBillToContext();
  const { billingAccountNumber, billingNumber } = useAccountDetails();

  const initialValues: BillToFormValues = useMemo(() => {
    return {
      [BillToFormFields.AccountNumber]: selectedAccountUrn ?? billingAccountNumber ?? EMPTY_VALUE,
      [BillToFormFields.BillingNumber]: billingNumber ?? EMPTY_VALUE,
      [BillToFormFields.HiddenAccountNumber]: selectedAccountUrn ?? billingAccountNumber ?? EMPTY_VALUE,
    };
  }, [billingAccountNumber, billingNumber, selectedAccountUrn]);
  const validationSchema = useMemo(() => {
    return Yup.object().shape({
      [BillToFormFields.AccountNumber]: Yup.string()
        .trim()
        .required(t('validation.requiredField'))
        .min(5, t('validation.minFieldLength', { fieldLength: 3 }))
        .matches(/^[a-z0-9]*$/i, t('validation.invalidField')),
      [BillToFormFields.BillingNumber]: Yup.string()
        .trim()
        .required(t('validation.requiredField'))
        .min(5, t('validation.minFieldLength', { fieldLength: 5 }))
        .max(10, t('validation.minFieldLength', { fieldLength: 10 }))
        .matches(/^[1-9]\d{4,9}$/, t('validation.invalidField')),
    });
  }, [t]);
  const resolver = useYupValidationResolver(validationSchema);
  const formMethods = useForm({
    resolver: resolver,
    defaultValues: initialValues,
  });
  const accountNumber = formMethods.watch(BillToFormFields.AccountNumber);

  const handleSubmit = async (values: BillToFormValues) => {
    const accountNumber = values[BillToFormFields.AccountNumber];
    const billingNumber = values[BillToFormFields.BillingNumber];
    const { errorMessage } = await addOrModifyBillTo({
      accountNumber,
      billingNumber,
      handleCloseModal,
    });
    if (errorMessage) {
      formMethods.setError(BillToFormFields.BillingNumber, { message: errorMessage });
    }
  };
  const onFormSubmit = formMethods.handleSubmit(handleSubmit);

  return (
    <FormProvider {...formMethods}>
      <Box>
        {isAccountNumberFieldHidden ? (
          <EhiButton variant={'text'} onClick={onBackClicked} startIcon={<ArrowBackIcon />}>
            <Body2>{t('common.back')}</Body2>
          </EhiButton>
        ) : (
          <AccountNumberSearchContainer data-testid='billingAccountNumberContainer' paddingBottom={ehiTheme.spacing(2)}>
            <Grid item>
              <Body1>{t('rateAndBilling.enterAccountNumber')}</Body1>
            </Grid>
            <Grid item>
              <StyledFlexGrid data-testid='searchField' spacing={1}>
                <Grid item xs={6} sm={6}>
                  <FormTextField
                    name={BillToFormFields.AccountNumber}
                    label={t('rateAndBilling.accountNumber')}
                    data-testid='accountNumber'
                    fullWidth
                    required
                    InputProps={{
                      endAdornment: (
                        <InputIconButton
                          icon={<FieldClearIcon />}
                          label={t('common.clear')}
                          onClick={() => {
                            formMethods.setValue(BillToFormFields.AccountNumber, EMPTY_VALUE);
                            formMethods.setValue(BillToFormFields.HiddenAccountNumber, EMPTY_VALUE);
                            formMethods.clearErrors(BillToFormFields.AccountNumber);
                          }}
                          disabled={accountNumber?.length === 0}
                        />
                      ),
                    }}
                  />
                </Grid>
                <Grid item xs={3} sm={3}>
                  <EhiButton
                    data-testid='account-applyButton'
                    variant='contained'
                    style={{ marginTop: 0 }}
                    disabled={!accountNumber || accountNumber.length === 0}
                    onClick={async () => {
                      // Note: manually trigger validation as form submit validation doesn't happen for AccountNumber
                      // If account number is invalid, there is no reason to make the API call triggered by setting these values
                      const isValid = await formMethods.trigger([BillToFormFields.AccountNumber]);
                      if (isValid) {
                        formMethods.setValue(BillToFormFields.BillingNumber, EMPTY_VALUE);
                        formMethods.setValue(
                          BillToFormFields.HiddenAccountNumber,
                          formMethods.getValues(BillToFormFields.AccountNumber)
                        );
                      } else {
                        formMethods.setError(BillToFormFields.AccountNumber, {
                          message: t('rateAndBilling.invalidAccountNumber'),
                        });
                      }
                    }}>
                    {t('common.apply')}
                  </EhiButton>
                </Grid>
              </StyledFlexGrid>
            </Grid>
          </AccountNumberSearchContainer>
        )}
        {/* Hidden account number is used so that we can trigger the query without searching on every keystroke */}
        <BillingAccountInfo onFormSubmit={onFormSubmit} />
        <ProgressOverlay inProgress={formMethods.formState.isSubmitting} />
      </Box>
    </FormProvider>
  );
};

const BillingAccountInfo: FC<{
  onFormSubmit: () => void;
}> = ({ onFormSubmit }) => {
  const { t } = useTranslations();
  const { showAlert } = useAlert();
  const { watch, setValue, clearErrors, setError, formState } = useFormContext();
  const billingNumberHasError = formState.errors[BillToFormFields.BillingNumber] !== undefined;
  const billingNumber = watch(BillToFormFields.BillingNumber);
  const accountNumber = watch(BillToFormFields.HiddenAccountNumber);
  const { data: account, error: accountErrors, isFetching: accountLoading } = useBusinessAccountQuery(accountNumber);
  const {
    data: billingAccount,
    error: billingAccountErrors,
    isFetching: billingAccountLoading,
  } = useBillingAccountByAccountQuery(accountNumber);
  const { data: contact } = useAccountContactInfoQuery(accountNumber);
  const { checkBillingAccountErrors } = useBillTo();

  const accountDetails = useMemo(() => {
    return transformAccountDetailsFromRetrieve(account, contact);
  }, [account, contact]);

  useEffect(() => {
    if (billingAccount && billingAccount.urn) {
      setValue(BillToFormFields.BillingNumber, parseUrn(billingAccount.urn), { shouldValidate: true });
    } else if (!billingAccount && billingAccountErrors) {
      const { errorMessage } = checkBillingAccountErrors(billingAccountErrors);
      errorMessage && setError(BillToFormFields.AccountNumber, { message: errorMessage });
    }
  }, [billingAccountErrors, billingAccount, setValue, checkBillingAccountErrors, setError]);

  useEffectWhen(() => {
    // if not billing account errors, then show account errors
    if (!billingAccountErrors) {
      if (accountErrors?.status === HttpStatusCode.NotFound) {
        setError(BillToFormFields.AccountNumber, { message: accountErrors?.errors?.[0].localizedMessage });
      } else {
        void showAlert({
          variant: 'error',
          responseMessages: accountErrors?.errors,
        });
      }
    }
  }, !!accountErrors);

  return billingAccount || account ? (
    <>
      <GridContainer style={{ padding: ehiTheme.spacing(2) }}>
        {accountDetails && <BusinessAccountCard account={accountDetails} titleColor={'black'} />}
      </GridContainer>
      {billingAccountErrors?.status !== HttpStatusCode.NotFound && (
        <Box style={{ padding: ehiTheme.spacing(0, 2, 1) }}>
          <GridContainer
            data-testid='billingNumberContainer'
            style={{ background: '#f5f5f5', paddingBottom: ehiTheme.spacing(2) }}
            alignItems={'center'}
            marginTop={'auto'}>
            <Grid item>
              <Body1>{t('rateAndBilling.billTo.enterBillingNumber')}</Body1>
            </Grid>
            <GridContainer
              display={'flex'}
              spacing={1}
              style={{ paddingTop: ehiTheme.spacing(2), paddingLeft: ehiTheme.spacing(2) }}>
              <Grid item xs={6} sm={6}>
                <MaskedTextField
                  name={BillToFormFields.BillingNumber}
                  label={t('rateAndBilling.billTo.billingNumber')}
                  required
                  autoFocus={!!account}
                  exceptLast={4}
                  inputProps={{
                    minLength: 5,
                    maxLength: 10,
                  }}
                  InputProps={{
                    endAdornment: (
                      <InputIconButton
                        icon={<FieldClearIcon />}
                        label={t('common.clear')}
                        onClick={() => {
                          setValue(BillToFormFields.BillingNumber, EMPTY_VALUE);
                          clearErrors(BillToFormFields.BillingNumber);
                        }}
                        disabled={billingNumber?.length === 0}
                      />
                    ),
                  }}
                />
              </Grid>
              <Grid item xs={3} sm={3}>
                <EhiButton
                  data-testid='billingNumber-applyButton'
                  variant='contained'
                  style={{ marginTop: 0 }}
                  disabled={billingNumberHasError || !billingNumber}
                  onClick={onFormSubmit}>
                  {t('common.apply')}
                </EhiButton>
              </Grid>
            </GridContainer>
          </GridContainer>
        </Box>
      )}
      <ProgressOverlay inProgress={accountLoading || billingAccountLoading} />
    </>
  ) : (
    <></>
  );
};
