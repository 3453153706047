import { FC } from 'react';
import { Title } from 'components/shared/ui/styles/Typography.styles';
import { StyledCardAction, StyledCardHeader } from 'components/shared/ui/card/Card.styles';
import { CardHeaderProps } from 'components/shared/ui/card/CardTypes';

export const CardHeader: FC<CardHeaderProps> = ({ title, titleAdornment, headerActions, ...rest }) => {
  const titleContent = title && (
    <Title color={rest.onClick ? 'primary' : 'textPrimary'}>
      {title}
      {titleAdornment}
    </Title>
  );

  const headerButtons = [];

  if (headerActions) {
    headerButtons.push(
      ...headerActions.map((action) => {
        return (
          <StyledCardAction
            variant={action.variant || 'text'}
            key={action.label}
            disabled={action.disabled || false}
            onClick={action.onClick}
            data-testid={action.testId}
            startIcon={action.icon}
            color={action.color || 'success'}>
            {action.label}
          </StyledCardAction>
        );
      })
    );
  }

  return <StyledCardHeader title={titleContent} action={headerButtons} className={rest.className} />;
};
