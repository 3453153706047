import {
  CardDetailsContainer,
  ErrorMessage,
  ErrorMessageContainer,
  LoyaltyPill,
  PaymentDetailsContainer,
  PaymentDetailsItem,
  RenterChargesContainer,
} from 'components/flexFlow/payment/Payment.styles';
import { Body2, ehiTheme, Subtitle1 } from '@ehi/ui';
import { FC, useCallback, useMemo } from 'react';
import {
  checkForExpiredCard,
  formatExpirationDate,
  getCCBrand,
  getPaymentType,
  paymentTypes,
  transformPaymentDetails,
} from 'utils/paymentUtils';
import { useLocale, useTranslations } from 'components/shared/i18n';
import { EhiDivider } from 'components/shared/ui/styles/Divider.styles';
import { maskCreditCard } from 'utils/maskUtils';
import { DriverData } from 'components/shared/uiModels/driver/driverDataTypes';
import { loadEhiLocationCookie } from '@ehi/location';
import { useLocationQuery } from 'services/location/locationQueries';
import { useDateTimeFormater } from 'utils/routing/useDatetimeFormater';
import { FieldErrorIcon } from 'components/shared/ui/FieldErrorIcon';
import { CardBrand } from 'services/payment/paymentReferenceTypes';
import { Box } from '@mui/material';
import { useAppSelector } from 'redux/hooks';
import { selectCharges, selectPickup } from 'redux/selectors/bookingEditor';
import { OptionItem } from 'components/shared/forms/FormFieldTypes';
import { parseUrn } from 'utils/urnUtils';

type PaymentDetailsProps = {
  selectedValue: OptionItem;
  driverData: DriverData;
  cardBrands?: CardBrand[];
};

export const PaymentDetails: FC<PaymentDetailsProps> = ({ selectedValue, driverData, cardBrands }) => {
  const { t } = useTranslations();
  const { locale } = useLocale();
  const cookieLocation = loadEhiLocationCookie();
  const { getLocalizedDateTime } = useDateTimeFormater();
  const charges = useAppSelector(selectCharges);
  const pickupData = useAppSelector(selectPickup);
  const { data: locationQuery } = useLocationQuery(parseUrn(pickupData?.branch) ?? cookieLocation?.peoplesoftId ?? '');

  const paymentDetails = useMemo(() => {
    return transformPaymentDetails(driverData, locale, charges);
  }, [driverData, charges, locale]);

  const cardExpired = useCallback(
    (expiration?: string) => {
      return checkForExpiredCard(t, getLocalizedDateTime, expiration, locationQuery?.location?.timezone);
    },
    [getLocalizedDateTime, locationQuery?.location?.timezone, t]
  );

  return (
    <PaymentDetailsContainer data-testid={'paymentDetailsSection'}>
      {paymentDetails.paymentMethods && selectedValue.id === paymentTypes.Credit_Card ? (
        paymentDetails.paymentMethods.map((method, index) => {
          return (
            <Box key={index}>
              {/* {index !== 0 && <EhiDivider style={{ marginTop: ehiTheme.spacing(2) }} />} */}
              <PaymentDetailsItem data-testid={`paymentDetails-${index + 1}`}>
                <Subtitle1 bold>{paymentDetails.payerName}</Subtitle1>
                <CardDetailsContainer>
                  <Body2 data-testid={'paymentType'}>{getPaymentType(t, method?.type)}</Body2>
                  <Body2 data-testid={'ccName'}>{getCCBrand(method?.cardBrand, cardBrands)}</Body2>
                  <Body2 data-testid={'maskedCardNumber'}>
                    {maskCreditCard(method?.cardNumberLastFour, method?.cardBrand)}
                  </Body2>
                  <Body2 data-testid={'cardExpiration'}>{`${t('payment.exp').toUpperCase()} ${formatExpirationDate(
                    t,
                    method?.expiration
                  )}`}</Body2>
                  <LoyaltyPill data-testid={'loyaltyPill'}>{`${t('driver.loyalty')} ${index + 1}`}</LoyaltyPill>
                </CardDetailsContainer>
                {cardExpired(method?.expiration) && (
                  <ErrorMessageContainer data-testid={'errorMessageContainer'}>
                    <FieldErrorIcon />
                    <ErrorMessage>{t('payment.cardExpired')}</ErrorMessage>
                  </ErrorMessageContainer>
                )}
              </PaymentDetailsItem>
              <EhiDivider style={{ marginTop: ehiTheme.spacing(2) }} />
            </Box>
          );
        })
      ) : (
        <Box>
          <PaymentDetailsItem data-testid={'paymentDetails'}>
            <Subtitle1 bold>{paymentDetails.payerName}</Subtitle1>
            <CardDetailsContainer>
              <Body2 data-testid={'paymentType'}>{selectedValue.label}</Body2>
            </CardDetailsContainer>
          </PaymentDetailsItem>
          <EhiDivider style={{ marginTop: ehiTheme.spacing(2) }} />
        </Box>
      )}
      <>
        <RenterChargesContainer>
          <Body2 style={{ letterSpacing: '0.25px' }} data-testid={'estimatedRenterCharges'}>
            {t('payment.estimatedRenterCharges')}
          </Body2>
          <Subtitle1 bold>{paymentDetails.renterCharges}</Subtitle1>
        </RenterChargesContainer>
      </>
    </PaymentDetailsContainer>
  );
};
