import { FullScreenSpinnerContainer } from 'components/shared/ui/spinner/spinner.styles';
import { CircularProgress } from '@mui/material';
import { FC } from 'react';

export const FullScreenSpinner: FC = () => {
  return (
    <FullScreenSpinnerContainer>
      <CircularProgress data-testid={'loadingCircle'} />
    </FullScreenSpinnerContainer>
  );
};
