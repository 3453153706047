import { getDefaultAxiosInstance } from 'components/shared/axios/axiosConfiguration';
import {
  CreateDriverProfileRequest,
  DriverProfile,
  LoyaltyMembership,
  LoyaltyProgram,
  ModifyContactInfoRequestObject,
  ModifyContactInfoResponseObject,
  ModifyWholeProfileRequestObject,
  SearchResult,
  WholeDriverProfileResponse,
} from 'services/renter/driverProfile/driverProfileTypes';
import {
  ACCEPT,
  CONTENT_TYPE,
  EHI_ACCEPT_PROTECTED_DATA,
  EHI_LOYALTY_PROGRAM,
  PROTECTED_DATA_VALUE,
} from 'services/headerConstants';

export const CONTENT_TYPE_PROFILE_BY_MEMBERSHIP_NUMBER =
  'application/prs.ehi-com.driver-profile-by-membership-number+json';
export const ACCEPT_LOYALTY_PROGRAM = 'application/prs.com-ehi.loyalty-program+json';

const ROOT_PATH = '/renter';
const DRIVER_PROFILE_PATH = '/driverProfile';
const MODIFY_CONTACT_INFO = '/modify-contact-information';
const MODIFY_DRIVER_PROFILE = '/modify-driver-profile';
const LOYALTY_MEMBERSHIP = '/loyaltyMembership';
const LOYALTY_PROGRAM = '/loyaltyProgram';
const SEARCH = '/search';

const LOYALTY_PROGRAMS = ['ALAMO_INSIDERS', 'ENTERPRISE_PLUS', 'EMERALD_CLUB'];

export const createDriverProfile = async (driverProfileRequest: CreateDriverProfileRequest): Promise<DriverProfile> => {
  const axiosInstance = getDefaultAxiosInstance();
  const response = await axiosInstance.post(`${ROOT_PATH}${DRIVER_PROFILE_PATH}`, driverProfileRequest, {
    genericUrl: `${ROOT_PATH}${DRIVER_PROFILE_PATH}`,
    headers: {
      [EHI_ACCEPT_PROTECTED_DATA]: PROTECTED_DATA_VALUE,
    },
  });

  return response.data;
};

export const modifyDriverProfile = async (
  driverUuid: string,
  request: ModifyWholeProfileRequestObject
): Promise<WholeDriverProfileResponse> => {
  const axiosInstance = getDefaultAxiosInstance();
  const response = await axiosInstance.post(
    `${ROOT_PATH}${DRIVER_PROFILE_PATH}/${driverUuid}${MODIFY_DRIVER_PROFILE}`,
    request,
    {
      genericUrl: `${ROOT_PATH}${DRIVER_PROFILE_PATH}/{uuid}${MODIFY_DRIVER_PROFILE}`,
      headers: {
        [EHI_ACCEPT_PROTECTED_DATA]: PROTECTED_DATA_VALUE,
      },
    }
  );

  return response.data;
};

export const modifyContactInformation = async (
  driverUuid: string,
  request: ModifyContactInfoRequestObject
): Promise<ModifyContactInfoResponseObject> => {
  const axiosInstance = getDefaultAxiosInstance();
  const response = await axiosInstance.post(
    `${ROOT_PATH}${DRIVER_PROFILE_PATH}/${driverUuid}${MODIFY_CONTACT_INFO}`,
    request,
    {
      genericUrl: `${ROOT_PATH}${DRIVER_PROFILE_PATH}/{uuid}${MODIFY_CONTACT_INFO}`,
      headers: {
        [EHI_ACCEPT_PROTECTED_DATA]: PROTECTED_DATA_VALUE,
      },
    }
  );

  return response.data;
};

export const getDriverProfile = async (driverUuid: string): Promise<DriverProfile> => {
  const axiosInstance = getDefaultAxiosInstance();
  const response = await axiosInstance.get(`${ROOT_PATH}${DRIVER_PROFILE_PATH}/${driverUuid}`, {
    genericUrl: `${ROOT_PATH}${DRIVER_PROFILE_PATH}/{uuid}`,
    headers: {
      [EHI_ACCEPT_PROTECTED_DATA]: PROTECTED_DATA_VALUE,
    },
  });

  return response.data;
};

export const getDriverProfileLoyaltyMembership = async (
  driverUuid: string,
  membershipProgram: string
): Promise<LoyaltyMembership> => {
  const axiosInstance = getDefaultAxiosInstance();
  const response = await axiosInstance.get(`${ROOT_PATH}${DRIVER_PROFILE_PATH}/${driverUuid}${LOYALTY_MEMBERSHIP}`, {
    genericUrl: `${ROOT_PATH}${DRIVER_PROFILE_PATH}/{uuid}${LOYALTY_MEMBERSHIP}`,
    headers: {
      [EHI_ACCEPT_PROTECTED_DATA]: PROTECTED_DATA_VALUE,
      [EHI_LOYALTY_PROGRAM]: membershipProgram,
    },
  });

  return response.data;
};

export const searchDriverByLoyaltyMembership = async (loyaltyNumber: string, program: string) => {
  const response = await getDefaultAxiosInstance().post(
    `${ROOT_PATH}${DRIVER_PROFILE_PATH}${SEARCH}`,
    { membershipNumber: loyaltyNumber, loyaltyProgram: program },
    {
      genericUrl: `${ROOT_PATH}${DRIVER_PROFILE_PATH}${SEARCH}`,
      headers: {
        [EHI_ACCEPT_PROTECTED_DATA]: PROTECTED_DATA_VALUE,
        [CONTENT_TYPE]: CONTENT_TYPE_PROFILE_BY_MEMBERSHIP_NUMBER,
      },
    }
  );
  return response.data;
};

export const searchRenterWithLoyalty = async (loyaltyNumber: string): Promise<SearchResult[]> => {
  const searchResults = await Promise.all(
    LOYALTY_PROGRAMS.map((program: string) => {
      return searchDriverByLoyaltyMembership(loyaltyNumber, program);
    })
  );
  // Note: Service returns all the 3 profiles with results array, return the results which has profile data
  return searchResults
    .map((searchResult) => searchResult.results)
    .reduce((previousValue, currentValue) => [...previousValue, ...currentValue], []);
};

export const getLoyaltyProgramById = async (loyaltyProgram: string): Promise<LoyaltyProgram> => {
  const axiosInstance = getDefaultAxiosInstance();
  const response = await axiosInstance.get(`${ROOT_PATH}${LOYALTY_PROGRAM}/${loyaltyProgram}`, {
    genericUrl: `${ROOT_PATH}${LOYALTY_PROGRAM}/{loyaltyProgram}`,
    headers: {
      [ACCEPT]: ACCEPT_LOYALTY_PROGRAM,
    },
  });

  return response.data;
};

export const getLoyaltyPrograms = async (): Promise<LoyaltyProgram[]> => {
  return Promise.all(
    LOYALTY_PROGRAMS.map((loyaltyProgram: string) => {
      return getLoyaltyProgramById(loyaltyProgram);
    })
  );
};
