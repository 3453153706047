import { useContext, ReactNode, FC } from 'react';
import { SecretsContext } from 'context/secrets/SecretsContext';
import { APIProvider } from '@vis.gl/react-google-maps';

interface MapsProps {
  children: ReactNode;
}

export const MapsProvider: FC<MapsProps> = ({ children }) => {
  const {
    secrets: { reservationsGoogleMapsApiKey },
  } = useContext(SecretsContext);
  return <APIProvider apiKey={reservationsGoogleMapsApiKey}>{children}</APIProvider>;
};
