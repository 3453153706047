import { FC } from 'react';
import SvgIcon from '@mui/material/SvgIcon';
import { Error } from '@mui/icons-material';
import { IconWrapperProps } from './ProgressIndicatorTypes';

const IconWrapper: FC<IconWrapperProps> = ({ component, className, ...rest }: IconWrapperProps) => {
  return <SvgIcon component={component ?? Error} className={className} {...rest} />;
};

export default IconWrapper;
