import { useMap } from '@vis.gl/react-google-maps';
import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { type Marker, MarkerClusterer } from '@googlemaps/markerclusterer';
import { BranchLocation, BranchLookupMarker } from './BranchLookupMarker';

export type ClusteredBranchLookupMarkersProps = {
  branches: BranchLocation[];
  handleMarkerClick: (stationId: string) => void;
};

export const ClusteredBranchLookupMarkers: FC<ClusteredBranchLookupMarkersProps> = ({
  branches,
  handleMarkerClick,
}) => {
  const [markers, setMarkers] = useState<{ [key: number]: Marker }>({});

  // create the markerClusterer once the map is available and update it when
  // the markers are changed
  const map = useMap();
  const clusterer = useMemo(() => {
    if (!map) return null;

    return new MarkerClusterer({ map });
  }, [map]);

  useEffect(() => {
    if (!clusterer) return;

    clusterer.clearMarkers();
    clusterer.addMarkers(Object.values(markers));
  }, [clusterer, markers]);

  // this callback will effectively get passed as ref to the markers to keep
  // tracks of markers currently on the map
  const setMarkerRef = useCallback((marker: Marker | null, key: number) => {
    setMarkers((markers) => {
      if ((marker && markers[key]) || (!marker && !markers[key])) return markers;

      if (marker) {
        return { ...markers, [key]: marker };
      } else {
        const { ...newMarkers } = markers;

        return newMarkers;
      }
    });
  }, []);

  return (
    <>
      {branches.map((branch) => (
        <BranchLookupMarker
          key={branch.key}
          branch={branch}
          onClick={(branchLocation: BranchLocation) => handleMarkerClick(branchLocation?.stationId ?? '')}
          setMarkerRef={setMarkerRef}
        />
      ))}
    </>
  );
};
