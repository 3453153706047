import { createContext, FC, useCallback, useContext, useRef, useState, ReactNode } from 'react';

import { AlertDialog } from 'components/shared/alert/AlertDialog';
import { AlertContextProps, AlertOptions, SelectedAction } from 'components/shared/alert/AlertDialogTypes';
import { AppErrorCode, BookingErrorCodes, SpecialErrorCodes } from 'utils/errorUtils';
import NetworkError from 'components/shared/errors/NetworkError';
import { ResponseMessageDialog } from 'components/shared/alert/ResponseMessageDialog';
import { RefreshEditorDialog } from 'components/shared/alert/RefreshEditorDialog';

export const AlertContext = createContext<AlertContextProps>({
  showAlert: () => {
    return Promise.reject(new Error('Context is not setup'));
  },
  hideAlert: () => {
    // do nothing
  },
});

export const useAlert = (): AlertContextProps => useContext(AlertContext);

export const AlertProvider: FC<{ children?: ReactNode }> = ({ children }) => {
  const [alertState, setAlertState] = useState<AlertOptions | null>(null);
  const awaitingPromiseRef = useRef<{ resolve: (_selected?: SelectedAction) => void }>();

  const showAlert = useCallback((options: AlertOptions): Promise<SelectedAction | undefined> => {
    setAlertState(options);
    return new Promise<SelectedAction | undefined>((resolve) => {
      awaitingPromiseRef.current = { resolve };
    });
  }, []);

  const hideAlert = (selected?: SelectedAction) => {
    setAlertState(null);
    awaitingPromiseRef.current?.resolve(selected);
  };

  let alertContent = null;
  if (alertState?.responseMessages) {
    const isNetworkTimeout = alertState.responseMessages.find(
      (error) => error.code === SpecialErrorCodes.NetworkTimeout || error.code === AppErrorCode.networkError
    );

    // Refresh editor only when backend service is down and editor returns 500 with error code = BOOK1000
    const refreshEditor = alertState.responseMessages.find(
      (error) => error.code === BookingErrorCodes.SupportingServiceError
    );

    if (isNetworkTimeout) {
      alertContent = <NetworkError />;
    } else if (refreshEditor) {
      alertContent = (
        <RefreshEditorDialog message={alertState?.responseMessages?.[0].localizedMessage || ''} onClose={hideAlert} />
      );
    } else {
      alertContent = <ResponseMessageDialog {...alertState} open={!!alertState} onClose={hideAlert} />;
    }
  } else if (alertState?.description) {
    alertContent = <AlertDialog {...alertState} open={!!alertState} onClose={hideAlert} />;
  }

  return (
    <AlertContext.Provider value={{ showAlert, hideAlert }}>
      {children}
      {alertContent}
    </AlertContext.Provider>
  );
};
