import { FC } from 'react';
import { Grid } from '@mui/material';
import { Body1, Body2, Caption, EhiButton, ehiTheme, H6 } from '@ehi/ui';
import { EhiDivider } from 'components/shared/ui/styles/Divider.styles';
import { useTranslations } from 'components/shared/i18n';
import { AccountDetailsType } from 'components/flexFlow/rateAndBilling/RateAndBillingTypes';

export type BusinessAccountCardType = {
  account: AccountDetailsType;
  handleSelect?: (accountUrn: string) => void;
  titleColor?: string;
  showSelect?: boolean;
  showDivider?: boolean;
};

export const BusinessAccountCard: FC<BusinessAccountCardType> = ({
  account,
  handleSelect,
  titleColor,
  showSelect,
  showDivider,
}: BusinessAccountCardType) => {
  const { t } = useTranslations();

  return (
    <>
      <H6 color={titleColor ?? '#4d789d'} data-testid={'accountName'}>
        {account.name}
      </H6>
      <Grid
        container
        display={'flex'}
        justifyContent={'space-between'}
        marginTop={ehiTheme.spacing(0.5)}
        data-testid={'accountInfo'}>
        <Grid item display={'flex'}>
          <Caption color='textSecondary'>{t('rateAndBilling.accountNumberLabel')}</Caption>
          <Body1 margin={ehiTheme.spacing(-0.5, 0, 0, 1)}>{account.accountNumber}</Body1>
        </Grid>
        <Grid item>
          <Caption color='textSecondary'>{account.accountType}</Caption>
        </Grid>
      </Grid>
      <Grid
        container
        display={'flex'}
        justifyContent={'space-between'}
        marginTop={ehiTheme.spacing(0.5)}
        data-testid={'accountAddress'}>
        <Grid item sm={10}>
          <Body2>{account.accountAddress1} </Body2>
          <Body2> {account.accountAddress2} </Body2>
          <Body2>{account.phoneNumber} </Body2>
        </Grid>
        {showSelect && (
          <Grid item sm={2} marginRight={ehiTheme.spacing(-2)}>
            <EhiButton
              onClick={() => {
                handleSelect?.(account.accountNumberUrn);
              }}>
              {t('common.select')}
            </EhiButton>
          </Grid>
        )}
      </Grid>
      {showDivider && <EhiDivider style={{ marginTop: ehiTheme.spacing(1.5) }} />}
    </>
  );
};
