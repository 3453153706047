interface MaskingFormats {
  defaultMaskedValue: string;
}

const maskingFormats: Readonly<MaskingFormats> = {
  defaultMaskedValue: '••••••••',
};

/**
 * This method accepts a javascript object or a json string, if ANYTHING else is passed you get NOTHING.
 * @param value a masked json string
 * @param fieldsToMask
 */
export const maskBody = (value?: string | Record<string, any>, fieldsToMask?: Array<string>): string | undefined => {
  const replacer = (key: string, value: any) => {
    if (fieldsToMask?.includes(key.toLowerCase())) {
      return maskingFormats.defaultMaskedValue;
    }
    return value;
  };

  if (value) {
    if (typeof value === 'object') {
      return JSON.stringify(value, replacer);
    } else {
      try {
        // Parse pre-stringified json
        return JSON.stringify(JSON.parse(value), replacer);
      } catch {
        // there is no way to know if this is sensitive info without a "key", so just mask it
        return maskingFormats.defaultMaskedValue;
      }
    }
  }
  return undefined;
};

export const maskHeaders = (
  headers?: Record<string, string>,
  headersToMask?: Array<string>
): Record<string, string> | undefined => {
  const maskedHeaders: Record<string, string> = {};
  if (headers && typeof headers === 'object') {
    for (const [key, value] of Object.entries(headers)) {
      if (headersToMask?.includes(key.toLowerCase())) {
        maskedHeaders[key] = maskingFormats.defaultMaskedValue;
      } else {
        maskedHeaders[key] = value;
      }
    }
    return maskedHeaders;
  } else {
    return undefined;
  }
};

// exposed this for testing
export interface Headers {
  [key: string]: string;
}

export const MASK_CHARACTER = '•' as const;
export const MASK_CHARACTER_STAR = '*' as const;
export const amexCard = 'AX';

export const MASK_FOUR = MASK_CHARACTER.repeat(4);

export const mask = (original?: string, allExcept = 0): string => {
  if (original) {
    const unmasked = original.length > allExcept ? original.slice(original.length - allExcept) : original;
    return MASK_FOUR + unmasked;
  }
  return '';
};

export const maskCreditCard = (lastFour?: string, ccBrand?: string) => {
  if (!lastFour) {
    return '--';
  } else if (!ccBrand || ccBrand !== amexCard) {
    // NON-AMEX contains 16 digits total
    return MASK_CHARACTER_STAR.repeat(12) + lastFour;
  } else if (ccBrand === amexCard) {
    // AMEX contains 15 digits total
    return MASK_CHARACTER_STAR.repeat(11) + lastFour;
  }
};
