import Box from '@mui/material/Box';
import { styled } from '@mui/material';

export const DynamicBox = styled(Box)(({ theme }) => ({
  background: theme.palette.background.paper,
}));

export const FlexStartItem = styled('div')`
  display: flex;
  flex: 1 1 0;
  justify-content: flex-start;
  align-items: center;
`;

export const FlexEndItem = styled('div')`
  display: flex;
  flex: 1 1 0;
  justify-content: flex-end;
  align-items: center;
`;

export const CardContainer = styled('div')`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const WrapperBox = styled(Box)`
  position: sticky;
  top: 0;
  height: 56px;
  width: 100%;
  // Note: z-index increased to 2 to fix Vehicle Search field text displaying above StickyCardNavigation bar
  z-index: 2;
`;
