import { EHI_MESSAGES_HEADER } from 'utils/constants';
import { ResponseMessage } from 'services/types/ResponseMessageTypes';
import { ErrorSeverity } from '@ehi/analytics';
import { logError } from 'components/shared/logger/splunkLogger';

type Headers = {
  [key: string]: any;
};

/**
 * '% + ...' adds the percent sign to the beginning of each hexadecimal
 * character code in the resulting string to indicate that the character
 * is being percent-encoded. Percent encoding is a mechanism used to represent
 * special characters in a URL or other context where the character might not be
 * allowed or might cause an issue.
 *
 * ('00' + c.charCodeAt(0).toString(16)).slice(-2) converts the character to its ASCII
 * code in hexadecimal form, adds '00' to the beginning of the resulting string to ensure
 * it has two digits, then extracts the last two digits of the resulting string.
 *
 * REF: https://stackoverflow.com/questions/30106476/using-javascripts-atob-to-decode-base64-doesnt-properly-decode-utf-8-strings.
 *
 * @param parts - A string containing the Base64-encoded string with special characters
 * @returns The decoded string. If the input is falsy, return an empty string.
 */

const parseWarningsBase64WithSpecialCharacters = (parts: string | undefined): string => {
  try {
    if (parts) {
      return decodeURIComponent(
        atob(parts)
          .split('')
          .map((c) => {
            return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
          })
          .join('')
      );
    }
  } catch (error) {
    logError({
      error: {
        message: 'An error occurred while decoding the Base64-encoded string',
        supportInformation: { exception: error, encodeMessage: parts },
      },
      severity: ErrorSeverity.Warning,
    });
  }

  return '';
};

export const parseWarnings = (base64EncodedWarnings: string): ResponseMessage[] => {
  if (base64EncodedWarnings !== 'null') {
    const parts = base64EncodedWarnings.split(':');
    let content: string;

    if (parts[0].startsWith('b64')) {
      content = parseWarningsBase64WithSpecialCharacters(parts[parts.length - 1]);
    } else {
      content = atob(parts[parts.length - 1]);
    }

    return JSON.parse(content);
  }
  return [];
};

/**
 * Headers are not case sensitive;
 * This method extracts a header with any capitalization of a given key
 * Separate from `extractEhiMessagesHeader` for ability to move to its own utility later, if needed.
 */
export const extractHeader = (headers: Headers | undefined, key: string): any => {
  if (!headers) {
    return undefined;
  }

  if (key in headers) {
    return headers[key];
  }

  const lowercaseKey = key.toLocaleLowerCase();

  let k;
  for (k in headers) {
    if (k.toLocaleLowerCase() === lowercaseKey) {
      return headers[k];
    }
  }

  return undefined;
};

export const extractEhiMessagesHeader = (headers: Headers): string | undefined => {
  const messages = extractHeader(headers, EHI_MESSAGES_HEADER);

  if (typeof messages === 'string') {
    return messages;
  }

  return undefined;
};

/**
 * @param headers AxiosResponse headers
 * @returns ResponseMessage[] of warnings
 */
export const getWarningsFromResponseHeaders = (headers: Headers): ResponseMessage[] | undefined => {
  const messages = extractEhiMessagesHeader(headers);

  if (messages) {
    return parseWarnings(messages);
  }

  return undefined;
};
