import { FC, useCallback, useMemo, useState } from 'react';
import { useTranslations } from 'components/shared/i18n';
import { QuickResFields } from 'components/quickRes/QuickResTypes';
import { GridContainer, GridItem } from 'components/shared/ui/styles/Grid.styles';
import { SelectField } from 'components/shared/forms/SelectField';
import { PhoneTextField } from 'components/shared/forms/PhoneTextField';
import { StyledDivider } from 'components/quickRes/QuickRes.styles';
import { useAppSelector } from 'redux/hooks';
import { selectBookingEditorId } from 'redux/selectors/bookingEditor';
import { useFormContext, useWatch } from 'react-hook-form';
import { updateContactInformation } from 'services/booking/bookingService';
import { usePhoneTypesQuery } from 'services/renter/renterReferenceQueries';
import { useUpdateAndRefreshEditor } from 'hooks/bookingEditor/useUpdateAndRefreshEditor';
import { loadEhiLocationCookie } from '@ehi/location';
import { useCountriesQuery, useLocationQuery } from 'services/location/locationQueries';
import { ProgressOverlay } from 'components/shared/ui/spinner/ProgressOverlay';
import {
  generateOptionItemsWithCorporateCountries,
  generateOptionItemsWithNonCorporateCountries,
} from 'utils/formUtils';
import { SelectionVariant } from 'components/shared/forms/FormFieldTypes';
import { SelectMenu } from 'components/shared/forms/SelectMenu';
import { useMemoizedString } from 'hooks/useMemoizedOptions';
import { getDefaultCountry } from 'utils/locationUtils';
import { EMPTY_VALUE } from 'utils/constants';
import { Subtitle1 } from '@ehi/ui';
import { useQuickResModalContext } from 'components/quickRes/QuickResModalContext';
import { logDebug } from 'utils/logUtils';
import { useAlert } from 'components/shared/alert/AlertContext';

const QuickResContact: FC = () => {
  const { t } = useTranslations();
  const bookingEditorId = useAppSelector(selectBookingEditorId);
  const { updateAndRefresh } = useUpdateAndRefreshEditor();
  const [isContactUpdating, setIsContactUpdating] = useState<boolean>(false);
  const { onBlurFunction, setOnBlurFunction } = useQuickResModalContext();
  const { showAlert } = useAlert();

  const { data: phoneTypeDomain, isFetching: isPhoneTypeLoading } = usePhoneTypesQuery();
  const { data: countries, isFetching: isCountriesFetching } = useCountriesQuery();
  const cookie = loadEhiLocationCookie();
  const { string: defaultCountry, isLoading: isLocationQueryLoading } = useMemoizedString(
    useLocationQuery(cookie?.peoplesoftId ?? ''),
    getDefaultCountry
  );

  const contactTypes = useMemo(() => {
    return (
      phoneTypeDomain?.map((phone) => ({ label: phone.name || EMPTY_VALUE, value: phone.urn || EMPTY_VALUE })) || []
    );
  }, [phoneTypeDomain]);
  const {
    getFieldState,
    trigger,
    formState: { errors },
    getValues,
  } = useFormContext();
  const [countryCode] = useWatch({
    name: [QuickResFields.CountryCode],
  });

  const handlePhoneNumberOnBlur = useCallback(async (): Promise<void> => {
    const phone = getValues(QuickResFields.Phone);

    await trigger(QuickResFields.Phone);

    if (getFieldState(QuickResFields.Phone).isDirty && !errors[QuickResFields.Phone]) {
      try {
        setIsContactUpdating(true);
        const payload = {
          phone: { number: phone },
        };

        const result = await updateAndRefresh(() => updateContactInformation(bookingEditorId, payload));
        if (result?.errors?.length) {
          await showAlert({
            variant: 'error',
            description: result?.errors?.[0]?.localizedMessage ?? '',
            primaryActionText: 'dismiss',
          });
          setIsContactUpdating(false);
          return Promise.reject({
            message: result?.errors?.[0]?.localizedMessage,
          });
        } else {
          setIsContactUpdating(false);
          return Promise.resolve();
        }
      } catch (error) {
        setIsContactUpdating(false);
        return Promise.reject(error);
      }
    }
    return Promise.reject('form is not valid');
  }, [bookingEditorId, errors, getFieldState, getValues, showAlert, trigger, updateAndRefresh]);

  const runOnBlurFunction = useCallback(() => {
    onBlurFunction?.().catch(logDebug);
  }, [onBlurFunction]);

  const registerPhoneNumberOnBlurFunction = useCallback(() => {
    setOnBlurFunction(() => () => handlePhoneNumberOnBlur());
  }, [handlePhoneNumberOnBlur, setOnBlurFunction]);

  return (
    <GridContainer>
      <GridItem sm={12}>
        <Subtitle1 bold data-testid={'res-contact'}>
          {t('driver.contact')}
        </Subtitle1>
      </GridItem>
      <GridItem sm={4}>
        <SelectField
          name={QuickResFields.ContactType}
          label={t('driver.type')}
          data-testid={QuickResFields.ContactType}
          options={contactTypes || []}
          hasNoneOption={false}
        />
      </GridItem>
      <GridItem sm={4}>
        <SelectMenu
          formFieldName={QuickResFields.CountryCode}
          label={t('location.countryCode')}
          primaryList={{
            list: generateOptionItemsWithCorporateCountries(countries ?? []),
            subHeader: t('location.corporateCountries'),
          }}
          secondaryList={{
            list: generateOptionItemsWithNonCorporateCountries(countries ?? []),
            subHeader: t('location.otherCountries'),
          }}
          selectionVariant={SelectionVariant.CHECKMARK}
          searchFieldPlaceholder={t('location.searchForCountry')}
          footerText={t('common.reset')}
        />
      </GridItem>
      <GridItem sm={4}>
        <PhoneTextField
          country={countryCode ? countryCode : defaultCountry}
          name={QuickResFields.Phone}
          label={t('driver.phone')}
          data-testid={QuickResFields.Phone}
          onFocus={registerPhoneNumberOnBlurFunction}
          onBlur={runOnBlurFunction}
        />
      </GridItem>
      <GridItem xs={12} sm={12} style={{ paddingBottom: 0 }}>
        <StyledDivider />
      </GridItem>
      <ProgressOverlay
        inProgress={isContactUpdating || isPhoneTypeLoading || isCountriesFetching || isLocationQueryLoading}
      />
    </GridContainer>
  );
};
export default QuickResContact;
