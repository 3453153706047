import { TextFieldProps } from '@mui/material';
import useFieldFormatter, { formatString } from 'format-as-you-type';
import { useEffectWhen } from 'hooks/useEffectWhen';
import { FC, useState } from 'react';
import { Controller, ControllerFieldState, ControllerRenderProps, useFormContext } from 'react-hook-form';
import { EhiTextField } from 'components/shared/forms/EhiTextField';
import { FieldClearIcon } from 'components/shared/ui/FieldClearIcon';
import { InputIconButton } from 'components/shared/ui/InputIconButton/InputIconButton';
import { getPhoneFormatForCountry, valueTransform } from 'components/shared/forms/fomats';
import { PhoneTextFieldProps } from './FormFieldTypes';

export const PhoneTextField: FC<PhoneTextFieldProps> = ({ country, allowClearing = false, ...props }) => {
  const form = useFormContext();
  const format = getPhoneFormatForCountry(country);

  return (
    <Controller
      control={form.control}
      name={props.name}
      render={({ field, fieldState }) => (
        <FormattedTextField
          field={field}
          fieldState={fieldState}
          format={format}
          valueTransform={valueTransform}
          allowClearing={allowClearing}
          onBlur={async () => {
            await form.trigger(field.name);
          }}
          {...props}
        />
      )}
    />
  );
};

type FormattedTextFieldProps = TextFieldProps & {
  format: string;
  valueTransform?: (displayValue: string) => string;
  allowClearing: boolean;
  field: ControllerRenderProps;
  fieldState: ControllerFieldState;
};

const FormattedTextField: FC<FormattedTextFieldProps> = ({
  format,
  valueTransform,
  allowClearing,
  field,
  fieldState,
  ...rest
}) => {
  const { setValue, setFocus } = useFormContext();
  const [displayValue, setDisplayValue] = useState(field.value ? formatString(field.value, format) : field.value);

  const formatterProps = useFieldFormatter(
    (newInput: string, options: any) => formatString(newInput, format, options),
    (newInput: string) => {
      const sanitizedInput = newInput.replace(/[a-zA-Z]/g, '');
      setDisplayValue(sanitizedInput);
      setValue(field.name, valueTransform ? valueTransform(sanitizedInput) : sanitizedInput, {
        shouldDirty: true,
        shouldTouch: true,
      });
    },
    'inputRef'
  );

  useEffectWhen(() => {
    setDisplayValue(formatString(field.value, format));
  }, displayValue !== formatString(field.value, format));

  return (
    <EhiTextField
      {...rest}
      {...formatterProps}
      inputRef={field.ref}
      error={!!fieldState.error}
      helperText={fieldState.error?.message}
      value={displayValue}
      type={'tel'}
      inputProps={{ 'data-testid': field.name, 'aria-label': field.name }}
      InputProps={{
        ...rest.InputProps,
        endAdornment: (
          <>
            {allowClearing && (
              <InputIconButton
                onClick={() => {
                  setDisplayValue('');
                  setValue(field.name, '');
                  setFocus(field.name);
                }}
                icon={<FieldClearIcon />}
              />
            )}
            {rest.InputProps?.endAdornment}
          </>
        ),
      }}
    />
  );
};
