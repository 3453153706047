import { FlexiFunctionComponent } from 'components/shared/flexiFlow/FlexFlowTypes';
import { FlexFlowCard, FlexiFlowCardInnerContainer } from 'components/shared/ui/card/Card';
import { StickyCardNavigation } from 'components/shared/ui/card/StickyCardNavigation';
import { LoadingState } from 'components/shared/ui/spinner/loadableView/LoadableViewTypes';
import { useTranslations } from 'components/shared/i18n';
import { CardContent } from '@mui/material';
import { PoliciesSection } from 'components/flexFlow/review/sections/PoliciesSection';
import { EhiDivider } from 'components/shared/ui/styles/Divider.styles';
import { useContext, useEffect } from 'react';
import { ResSummaryDrawerContext } from 'context/resSummary/ResSummaryDrawerContext';
import { VehicleSection } from 'components/flexFlow/review/sections/VehicleSection';
import { EstimatedCharges } from 'components/flexFlow/review/sections/EstimatedCharges';
import { WhenAndWhereSection } from 'components/flexFlow/review/sections/WhenAndWhereSection';
import { ehiTheme } from '@ehi/ui';

export const Review: FlexiFunctionComponent = ({ onPrevious }) => {
  const { t } = useTranslations();
  const { openDrawer, setOpenDrawer } = useContext(ResSummaryDrawerContext);

  useEffect(() => {
    if (openDrawer) {
      setOpenDrawer(false);
    }
  }, [openDrawer, setOpenDrawer]);

  return (
    <FlexFlowCard loadingState={LoadingState.SUCCESS}>
      <StickyCardNavigation onPrevious={onPrevious} showExitToRentalSummary={false} isReviewPage={true} />
      <CardContent>
        <FlexiFlowCardInnerContainer
          data-testid='reviewPage'
          title={t('review.overView')}
          sx={{ border: 0, marginTop: ehiTheme.spacing(1) }}>
          <WhenAndWhereSection />
          <VehicleSection />
          <EhiDivider />
          <PoliciesSection />
          <EhiDivider />
          <EstimatedCharges />
        </FlexiFlowCardInnerContainer>
      </CardContent>
    </FlexFlowCard>
  );
};
