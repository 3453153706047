import { FC, useCallback, useState } from 'react';
import { Dialog } from 'components/shared/ui/dialogs/Dialog';
import { useTranslations } from 'components/shared/i18n';
import { FormControlLabel, Radio, RadioGroup } from '@mui/material';
import { ehiTheme } from '@ehi/ui';
import { useMediaQuery } from '@mui/system';
import {
  RateSourceFields,
  SearchByType,
} from 'components/flexFlow/rateAndBilling/editDialogs/rateSource/EditRateSourceDialogTypes';
import { RateSourceAccountNumberSearch } from 'components/flexFlow/rateAndBilling/editDialogs/rateSource/RateSourceAccountNumberSearch';
import { AccountSearch } from 'components/flexFlow/rateAndBilling/editDialogs/AccountSearch';
import { useRateSource } from 'components/flexFlow/rateAndBilling/editDialogs/rateSource/useRateSource';

type RateSourceDialogProps = {
  open: boolean;
  closeModal: () => void;
};

export const EditRateSourceDialog: FC<RateSourceDialogProps> = ({ open, closeModal }) => {
  const { t } = useTranslations();
  const [searchBy, setSearchBy] = useState<SearchByType>(SearchByType.AccountNumber);
  const isMobileOrTablet = useMediaQuery('(max-width:800px)');
  const { addOrModifyRateSource } = useRateSource();

  const handleClosingModal = useCallback(() => {
    setSearchBy(SearchByType.AccountNumber);
    closeModal();
  }, [closeModal]);

  return (
    <Dialog
      PaperProps={{
        sx: {
          height: '94%',
          width: isMobileOrTablet ? '94%' : '52%',
        },
      }}
      data-testid='rateSourceDialog'
      id='rateSourceDialog'
      contentPadding={0}
      open={open}
      title={t('rateAndBilling.rateSource')}
      a11yKey='content'
      fullScreen
      showDividers
      actions={{
        primaryAction: {
          label: t('common.cancel'),
          onClick: handleClosingModal,
        },
      }}>
      <RadioGroup row style={{ padding: ehiTheme.spacing(2) }}>
        <FormControlLabel
          name={RateSourceFields.SearchBy}
          checked={searchBy === SearchByType.AccountNumber}
          control={<Radio color='primary' />}
          label={t('rateAndBilling.accountNumber')}
          onClick={() => setSearchBy(SearchByType.AccountNumber)}
        />
        <FormControlLabel
          name={RateSourceFields.SearchBy}
          checked={searchBy === SearchByType.AccountSearch}
          control={<Radio color='primary' />}
          label={t('rateAndBilling.accountSearch')}
          onClick={() => setSearchBy(SearchByType.AccountSearch)}
        />
      </RadioGroup>
      {searchBy === SearchByType.AccountNumber ? (
        <RateSourceAccountNumberSearch handleCloseModal={handleClosingModal} />
      ) : (
        <AccountSearch
          handleSelect={(accountUrn: string) =>
            addOrModifyRateSource({ accountNumber: accountUrn, handleCloseModal: handleClosingModal })
          }
        />
      )}
    </Dialog>
  );
};
