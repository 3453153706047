import { styled } from '@mui/material/styles';
import { EhiButton, ehiTheme } from '@ehi/ui';
import { ButtonGroup } from '@mui/material';

export const StyledEhiButton = styled(EhiButton)`
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  padding-left: ${ehiTheme.spacing(1)};
  padding-right: ${ehiTheme.spacing(1)};
`;

export const StyledButtonGroup = styled(ButtonGroup)`
  display: flex;

  & .MuiButtonGroup-grouped {
    margin: 0;
  }
`;

export const ContentContainer = styled('div')`
  display: flex;
  flex-direction: column;
  overflow: hidden;
`;

export const StyledDialogContent = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  gap: ehiTheme.spacing(3),
});
