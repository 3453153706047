import { Grid, GridSize, Skeleton } from '@mui/material';
import { FC } from 'react';
import { DividerWithMargin } from 'components/shared/ui/styles/Divider.styles';
import { Card } from 'components/shared/ui/card/Card';
import { CardContentWithoutHeader } from 'components/shared/ui/card/Card.styles';
import { PlaceholderProps } from './PlaceHolderTypes';

/**
 * This is a generic component that can be used to build a loading view with Skeleton loading indicators for
 * components using the LoadableView. This is the preferred way to indicate to the user that data is loading.
 *
 * The default Placeholder will be a single Skeleton loading indicator.
 *
 * @see <a href="https://v4.mui.com/components/skeleton/">https://v4.mui.com/components/skeleton/</a>
 *
 * @param {'default' | 'card' | 'form'} [variant = 'default'] The type of view this will be used in place of.
 * @param { number } The number of rows of Skeletons to display.
 * @param { number } [columns = 1] The number of columns of Skeletons to display.
 * @param { number } [listCount = 1] The number of Placeholder views to display, the rows & columns will still apply to the content within the repeating view.
 * @param { boolean } [withHeader = false] If true, will display a larger Skeleton to mimic a header.
 */
export const Placeholder: FC<PlaceholderProps> = ({
  variant = 'default',
  rows = 1,
  columns = 1,
  listCount = 1,
  withHeader = false,
}) => {
  const buildSkeleton = (type?: 'header' | 'form', key?: number) => {
    let height = undefined;
    let width = undefined;

    switch (type) {
      case 'header':
        height = 30;
        width = 100;
        break;
      case 'form':
        height = 50;
        break;
    }

    return (
      <Skeleton
        animation='wave'
        height={height}
        width={width}
        key={'skeleton-' + key}
        data-testid={`${type ? type + '-' : ''}skeleton`}
      />
    );
  };

  const addRows = () => {
    const content = [];

    for (let index = 0; index < rows; index++) {
      if (variant === 'form') {
        content.push(buildSkeleton('form', index));
      } else {
        content.push(buildSkeleton(undefined, index));
      }
    }
    return content;
  };

  const addColumns = () => {
    const content = [];
    columns = columns > 12 ? 12 : columns;
    const gridWidth = Math.floor(12 / columns) as GridSize;
    for (let index = 0; index < columns; index++) {
      content.push(
        <Grid item sm={gridWidth} key={'column-' + index} data-testid={'column-skeleton'}>
          {addRows()}
        </Grid>
      );
    }
    return content;
  };

  const buildDefaultViewList = (count: number) => {
    const content = [];

    for (let index = 0; index < count; index++) {
      content.push(
        <div key={'view-' + index} data-testid={'list-skeleton'}>
          {withHeader && buildSkeleton('header')}
          <Grid container spacing={2}>
            {addColumns()}
          </Grid>
        </div>
      );

      if (index !== count - 1) {
        content.push(<DividerWithMargin key={'divider-' + index} />);
      }
    }
    return <div data-testid='placeholder'>{content}</div>;
  };

  const buildCardList = (count: number) => {
    const content = [];

    for (let index = 0; index < count; index++) {
      content.push(
        <Card key={index} data-testid={'card-skeleton'}>
          <CardContentWithoutHeader data-testid={'card-content-skeleton'}>
            {buildDefaultViewList(1)}
          </CardContentWithoutHeader>
        </Card>
      );
    }
    return <div data-testid='card-placeholder'>{content}</div>;
  };

  switch (variant) {
    case 'card':
      return buildCardList(listCount);
    case 'form':
    default:
      return buildDefaultViewList(listCount);
  }
};
