import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { useLocale } from 'components/shared/i18n/index';
import { DatePickerLocalizationProviderProps } from './LocalesTypes';
import { FC } from 'react';

export const DatePickerLocalizationProvider: FC<DatePickerLocalizationProviderProps> = ({ children }) => {
  const { locale } = useLocale();

  return (
    <LocalizationProvider dateAdapter={AdapterLuxon} adapterLocale={locale}>
      {children}
    </LocalizationProvider>
  );
};
