import { useQuery, UseQueryOptions, UseQueryResult } from '@tanstack/react-query';
import { EhiErrors } from 'services/types/EhiErrorsTypes';
import { DriverProfile, LoyaltyMembership, LoyaltyProgram } from 'services/renter/driverProfile/driverProfileTypes';
import { TRANSACTION_CACHE_OPTIONS } from 'context/queryClient/cacheOptions';
import {
  getDriverProfile,
  getDriverProfileLoyaltyMembership,
  getLoyaltyPrograms,
} from 'services/renter/driverProfile/driverProfileService';
import { useDomainQuery, useResScopeQuery } from 'context/queryClient/queryUtil';
import { useCallback } from 'react';
import { useMemoizedQueries } from 'shared/react-query/useMemoizedQueries';
import { getTransactionalProfile } from 'services/renter/transactionalProfile/transactionalProfileService';

const DRIVER_PROFILE_QUERY_KEY = 'driverProfile';

const DRIVER_LOYALTY_MEMBERSHIP_QUERY_KEY = 'driverLoyaltyMembership';
const LOYALTY_PROGRAMS_QUERY_KEY = 'LOYALTY_PROGRAMS';
const ADDITIONAL_DRIVER_PROFILE = 'additionalDriverProfile';
const ADDITIONAL_TRANSACTIONAL_PROFILE = 'transactionalDriverProfile';

export const useGetDriverProfileQuery = (
  driverProfileId: string,
  queryOptions: Partial<UseQueryOptions<DriverProfile, EhiErrors>> = {}
): UseQueryResult<DriverProfile, EhiErrors> => {
  const queryKey = [DRIVER_PROFILE_QUERY_KEY, driverProfileId];
  const queryFn = () => getDriverProfile(driverProfileId);
  const options = {
    ...TRANSACTION_CACHE_OPTIONS,
    ...queryOptions,
  };

  return useQuery<DriverProfile, EhiErrors>(queryKey, queryFn, options);
};

export const useGetAdditionalDriversQueries = (additionalDrivers: string[]) => {
  const { getAdditionalDriver } = useGetAdditionalDriverOptions();
  return useMemoizedQueries({
    queries: additionalDrivers.map((profile) => getAdditionalDriver({ profile })),
  });
};
const useGetAdditionalDriverOptions = () => {
  const getAdditionalDriver = useCallback(
    ({ profile }: { profile: string }) => ({
      queryKey: [ADDITIONAL_DRIVER_PROFILE, profile],
      queryFn: () => getDriverProfile(profile),
      enabled: !!profile,
      options: {
        ...TRANSACTION_CACHE_OPTIONS,
      },
    }),
    []
  );

  return { getAdditionalDriver };
};
export const useGetAdditionalTransactionalDriverQueries = (additionalDrivers: string[]) => {
  const { getAdditionalDriver } = useGetAdditionalTransactionalDriverOptions();
  return useMemoizedQueries({
    queries: additionalDrivers.map((profile) => getAdditionalDriver({ profile })),
  });
};

const useGetAdditionalTransactionalDriverOptions = () => {
  const getAdditionalDriver = useCallback(
    ({ profile }: { profile: string }) => ({
      queryKey: [ADDITIONAL_TRANSACTIONAL_PROFILE, profile],
      queryFn: () => getTransactionalProfile(profile),
      enabled: !!profile,
      options: {
        ...TRANSACTION_CACHE_OPTIONS,
      },
    }),
    []
  );

  return { getAdditionalDriver };
};

export const useGetDriverProfileLoyaltyMembership = (
  driverProfileId: string,
  membershipProgram: string
): UseQueryResult<LoyaltyMembership, EhiErrors> => {
  return useResScopeQuery({
    queryKey: [DRIVER_LOYALTY_MEMBERSHIP_QUERY_KEY, driverProfileId + '_' + membershipProgram],
    queryFn: () => getDriverProfileLoyaltyMembership(driverProfileId, membershipProgram),
    enabled: !!(driverProfileId && membershipProgram),
  });
};

export const useGetLoyaltyProgram = (enabled = true): UseQueryResult<LoyaltyProgram[], EhiErrors> => {
  return useDomainQuery({
    queryKey: LOYALTY_PROGRAMS_QUERY_KEY,
    queryFn: () => getLoyaltyPrograms(),
    ...{ enabled },
  });
};
