import { Body1, Body2, ehiTheme } from '@ehi/ui';
import { Card, styled } from '@mui/material';
import { Box } from '@mui/system';
import { CheckboxField } from 'components/shared/forms/CheckboxField';
import { Caption2 } from 'components/shared/ui/styles/Typography.styles';

export const PeoListItemContainer = styled(Card, {
  shouldForwardProp: (prop: string) => !['isSelected', 'isDisabled'].includes(prop),
})<{ isSelected: boolean; isDisabled: boolean }>(({ isSelected, isDisabled }) => {
  let backgroundColor;
  let color;

  if (isSelected) {
    backgroundColor = '#4D789D';
    color = 'white';
  }
  if (isDisabled) {
    backgroundColor = '#0000001F';
    color = '#00000061';
  }

  if (!isSelected && !isDisabled) {
    backgroundColor = 'white';
    color = '#4D789D';
  }

  return {
    color,
    padding: '0.75rem 1.5rem 0.75rem 1rem',
    display: 'grid',
    gridTemplateColumns: '3fr 1fr',
    marginBottom: '0.75rem',
    backgroundColor,
    '& .MuiCheckbox-root': {
      padding: 0,
    },
  };
});

export const PeoCheckboxContainer = styled('div')(() => ({
  display: 'flex',
  alignItems: 'flex-start',
}));

export const PeoTitle = styled(Body1)({
  color: ehiTheme.typography.body1.color,
  fontWeight: 700,
  fontSize: ehiTheme.typography.body1.fontSize,
  lineHeight: ehiTheme.typography.body1.lineHeight,
});

export const PeoCode = styled(Caption2, {
  shouldForwardProp: (prop: string) => !['isSelected', 'isDisabled'].includes(prop),
})<{ isSelected: boolean; isDisabled: boolean }>(({ isSelected, isDisabled }) => {
  let color;

  if (isSelected) {
    color = 'white';
  }
  if (isDisabled) {
    color = '#00000061';
  }
  if (!isSelected && !isDisabled) {
    color = '#00000061';
  }

  return {
    color,
  };
});

export const PeoAmount = styled(Body2, {
  shouldForwardProp: (prop: string) => prop !== 'isSelected',
})<{ isSelected?: boolean }>(({ isSelected }) => ({
  color: isSelected ? 'white' : '#000000DE',
  fontWeight: 700,
  fontSize: ehiTheme.typography.body2.fontSize,
  lineHeight: ehiTheme.typography.body2.fontSize,
}));

export const PeoSelectability = styled(Caption2, {
  shouldForwardProp: (prop: string) => !['isSelected', 'isDisabled', 'doNotOffer'].includes(prop),
})<{ isSelected: boolean; isDisabled: boolean; doNotOffer: boolean }>(({ isSelected, isDisabled, doNotOffer }) => {
  let color = '#00000061';
  let fontWeight = 400;

  if (isDisabled) {
    fontWeight = 700;
    if (!doNotOffer) fontWeight = 700;
  } else if (isSelected) {
    color = 'white';
  }

  return {
    color,
    fontWeight,
    textAlign: 'center',
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
  };
});

export const PeoQuantityAndRate = styled('div', {
  shouldForwardProp: (prop: string) => prop !== 'isDisabled',
})<{ isDisabled: boolean }>`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  &.displayQuantity {
    justify-content: space-between;
  }
`;

export const StyledPeoContainer = styled(Box)`
  margin: ${ehiTheme.spacing(1)};
  &.peoNiResults {
    height: 20vh;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

export const PeoListItemContainerReadOnly = styled(Card)(() => ({
  padding: '0.75rem 1.5rem 0.75rem 1rem',
  display: 'grid',
  gridTemplateColumns: '3fr 1fr',
  gap: '0.5rem',
  marginBottom: '0.75rem',
  '& .MuiCheckbox-root': {
    padding: 0,
  },
}));

export const PeoSelectabilityReadOnly = styled(Caption2)(() => ({
  color: '#00000061',
  textAlign: 'end',
}));

export const PeoQuantityAndRateReadOnly = styled('div')`
  display: flex;
  justify-content: flex-end;

  &.displayQuantity {
    justify-content: space-between;
  }
`;

export const PeoAmountReadOnly = styled(Body2)(() => ({
  color: '#000000DE',
  fontWeight: 700,
  fontSize: ehiTheme.typography.body2.fontSize,
  lineHeight: ehiTheme.typography.body2.fontSize,
  display: 'flex',
  justifyContent: 'flex-end',
}));

export const PeoCodeReadOnly = styled(Caption2)(() => ({
  color: 'rgba(0, 0, 0, 0.54)',
}));

export const PeoTitleReadOnly = styled(Body1)(() => ({
  color: ehiTheme.typography.body1.color,
  fontWeight: 700,
  fontSize: ehiTheme.typography.body1.fontSize,
  lineHeight: ehiTheme.typography.body1.lineHeight,
}));

export const PeoCheckboxContainerReadOnly = styled('div')(() => ({
  display: 'flex',
  gap: '0.5rem',
  alignItems: 'flex-start',
}));

export const StyledCheckbox = styled(CheckboxField, {
  shouldForwardProp: (prop: string) => !['isChecked', 'isDisabled'].includes(prop),
})<{ isChecked: boolean; isDisabled: boolean }>(({ isChecked, isDisabled }) => {
  let backgroundColor = 'transparent';
  let color = '#4D789D';

  if (isChecked && !isDisabled) {
    backgroundColor = '#4D789D';
    color = 'white';
  } else if (!isChecked && !isDisabled) {
    backgroundColor = 'transparent';
    color = '#4D789D';
  } else if (isChecked && isDisabled) {
    backgroundColor = '#E0E0E0';
    color = '#9E9E9E';
  } else if (!isChecked && isDisabled) {
    backgroundColor = '#E0E0E0';
    color = '#9E9E9E';
  }

  return {
    margin: ehiTheme.spacing(0, -1, 0, 1),
    backgroundColor,
    color,
    borderColor: color,
    '& svg': {
      fill: isChecked && !isDisabled ? 'white' : color,
    },
  };
});
