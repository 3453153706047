import { FC } from 'react';
import { SpinnerContainer, StyledSpinner } from './spinner.styles';
import { Backdrop } from '@mui/material';

export type ProgressOverlayProps = {
  inProgress: boolean;
  fullPageSpinner?: boolean;
};

export const ProgressOverlay: FC<ProgressOverlayProps> = ({ inProgress }: ProgressOverlayProps) => {
  return inProgress ? (
    <Backdrop
      open={inProgress}
      data-testid={'in-progress'}
      sx={{ backgroundColor: 'default', zIndex: Number.MAX_SAFE_INTEGER }}>
      <SpinnerContainer>
        <StyledSpinner />
      </SpinnerContainer>
    </Backdrop>
  ) : null;
};
