import Button from '@mui/material/Button';
import Snackbar from '@mui/material/Snackbar';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { useTranslations } from 'components/shared/i18n';
import { useResSnackbarContext } from 'context/resSnackbar/ResSnackbarContext';
import { EMPTY_VALUE } from 'utils/constants';
import { FC } from 'react';

export type SnackbarProps = {
  message: string;
  onViewReservation?: () => void;
  isOpen: boolean;
};
const autoHideDuration = 6000;

export const ReservationSnackBar: FC<SnackbarProps> = ({ message, onViewReservation, isOpen }) => {
  const { setSnackBarRes } = useResSnackbarContext();
  const { t } = useTranslations();

  const handleClose = () => {
    setSnackBarRes({ message: EMPTY_VALUE, isOpen: false });
  };
  const action = (
    <>
      {onViewReservation && (
        <Button
          data-testid={'viewButton'}
          color='secondary'
          sx={{ paddingTop: '3px', paddingBottom: '3px' }}
          onClick={onViewReservation}>
          {t('common.view')}
        </Button>
      )}

      <IconButton
        sx={{ paddingTop: '3px', paddingBottom: '3px' }}
        aria-label='close'
        color='inherit'
        onClick={handleClose}>
        <CloseIcon fontSize='small' />
      </IconButton>
    </>
  );

  return (
    <div>
      <Snackbar
        data-testid={'snackBar'}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        open={isOpen}
        autoHideDuration={autoHideDuration}
        onClose={handleClose}
        message={message}
        action={action}
        sx={{
          '& .MuiSnackbarContent-root': {
            paddingTop: '0px',
            paddingBottom: '0px',
          },
        }}
      />
    </div>
  );
};
