import {
  createContext,
  Dispatch,
  FC,
  PropsWithChildren,
  SetStateAction,
  useCallback,
  useContext,
  useState,
} from 'react';

export const QuickResModalContext = createContext<{
  onBlurFunction?: () => Promise<void>;
  setOnBlurFunction: Dispatch<SetStateAction<(() => Promise<void>) | undefined>>;
}>({
  onBlurFunction: undefined,
  setOnBlurFunction: () => undefined,
});

export const useQuickResModalContext = () => {
  const context = useContext(QuickResModalContext);

  if (!context) {
    throw Error('QuickResModalContext is not initialized');
  }

  return context;
};

export const QuickResModalProvider: FC<PropsWithChildren> = ({ children }) => {
  const [onBlurFunction, setOnBlurFunction] = useState<() => Promise<void>>();

  const executeAndClearOnBlur = useCallback(() => {
    if (onBlurFunction == undefined) {
      return Promise.resolve();
    }
    const promise = onBlurFunction?.();
    setOnBlurFunction(undefined);
    return promise;
  }, [onBlurFunction]);

  return (
    <QuickResModalContext.Provider
      value={{
        onBlurFunction: executeAndClearOnBlur,
        setOnBlurFunction,
      }}>
      {children}
    </QuickResModalContext.Provider>
  );
};
