import { FC, useMemo } from 'react';
import { SelectField } from 'components/shared/forms/SelectField';
import { useSubdivisionsQuery } from 'services/location/locationQueries';
import { Option } from 'components/shared/ui/OptionTypes';
import { generateAllCountrySubDivisionMenuItems } from 'utils/formUtils';
import { SelectFieldProps } from './FormFieldTypes';

export const CountrySubDivisionSelectField: FC<Omit<SelectFieldProps, 'options'> & { country: string }> = ({
  country,
  ...props
}) => {
  const { data } = useSubdivisionsQuery(country);
  const options = useMemo<Option<string>[]>(() => {
    if (!data?.length) return [];

    return generateAllCountrySubDivisionMenuItems(data);
  }, [data]);

  return <SelectField {...props} options={options} />;
};
