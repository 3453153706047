import { EhiButton } from '@ehi/ui';
import { useTranslations } from 'components/shared/i18n';
import { StyledH2, WarningContainer, ActionsContainer } from 'components/setLocation/SetLocation.styles';
import { AccessDeniedGraphic } from 'assets/app/AccessDeniedGraphic';
import { AccessDeniedErrorProps } from './AccessDeniedErrorTypes';
import { FC } from 'react';

export const AccessDeniedError: FC<AccessDeniedErrorProps> = ({ handleClose }) => {
  const { t } = useTranslations();

  return (
    <>
      <StyledH2>{t('common.accessDenied')}</StyledH2>
      <WarningContainer>
        <AccessDeniedGraphic />
        <div>{t('common.locationError')}</div>
      </WarningContainer>
      <ActionsContainer>
        <EhiButton
          primary
          variant='contained'
          onClick={() => {
            handleClose();
          }}
          data-testid={'close-button'}>
          {t('common.close').toUpperCase()}
        </EhiButton>
      </ActionsContainer>
    </>
  );
};
