import { FC } from 'react';
import { CheckCircle, Launch, RadioButtonUnchecked } from '@mui/icons-material';
import { SvgIconTypeMap } from '@mui/material';
import { StyledStepIcon } from 'components/shared/progressIndicator/Step.styles';
import { PageStatus, StepIconProps } from './ProgressIndicatorTypes';

export const StepIcon: FC<StepIconProps> = ({ status }: StepIconProps) => {
  let icon;
  let color: SvgIconTypeMap['props']['color'] | undefined;

  switch (status) {
    case PageStatus.Complete:
      icon = CheckCircle;
      color = 'success';
      break;
    case PageStatus.NavigationOnly:
      icon = Launch;
      break;
    case PageStatus.Incomplete:
    default:
      icon = RadioButtonUnchecked;
      break;
  }

  return <StyledStepIcon component={icon} color={color} />;
};
