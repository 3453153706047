import { ehiTheme } from '@ehi/ui';
import { StyledFlexFlowCard } from 'components/shared/ui/card/Card.styles';
import {
  CardContainer,
  DynamicBox,
  FlexEndItem,
  FlexStartItem,
  WrapperBox,
} from 'components/shared/ui/card/CardNavigation.styles';
import { CardContent, Divider, Grid, Skeleton } from '@mui/material';
import { FlexiFlowCardInnerContainer } from 'components/shared/ui/card/Card';
import { FC } from 'react';

export const FlexFlowCardSkeleton: FC = () => {
  return (
    <>
      <StyledFlexFlowCard variant='outlined' role={'region'}>
        <WrapperBox minHeight={ehiTheme.spacing(8)}>
          <DynamicBox>
            <CardContainer>
              <FlexStartItem>
                <Skeleton variant='rectangular' width={200} height={20} sx={{ margin: '20px' }} />
              </FlexStartItem>
              <FlexEndItem>
                <Skeleton variant='rectangular' width={100} height={20} sx={{ margin: '20px' }} />
              </FlexEndItem>
            </CardContainer>
            <Divider />
          </DynamicBox>
          <Divider />
        </WrapperBox>
        <Divider />
        <CardContent>
          <FlexiFlowCardInnerContainer>
            <Skeleton variant='text' sx={{ fontSize: '1.25rem', width: '25%' }} />
            <Grid container spacing={2}>
              <Grid item xs={4}>
                <Skeleton variant='text' sx={{ fontSize: '1.5rem', width: '100%' }} />
                <Skeleton variant='text' sx={{ fontSize: '1.5rem', width: '100%' }} />
                <Skeleton variant='text' sx={{ fontSize: '1.5rem', width: '100%' }} />
              </Grid>
              <Grid item xs={4}>
                <Skeleton variant='text' sx={{ fontSize: '1.5rem', width: '100%' }} />
                <Skeleton variant='text' sx={{ fontSize: '1.5rem', width: '100%' }} />
                <Skeleton variant='text' sx={{ fontSize: '1.5rem', width: '100%' }} />
              </Grid>
              <Grid item xs={4}>
                <Skeleton variant='text' sx={{ fontSize: '1.5rem', width: '100%' }} />
                <Skeleton variant='text' sx={{ fontSize: '1.5rem', width: '100%' }} />
                <Skeleton variant='text' sx={{ fontSize: '1.5rem', width: '100%' }} />
              </Grid>
            </Grid>
            <Skeleton variant='text' sx={{ fontSize: '1.25rem', width: '25%', marginTop: '2rem' }} />
            <Grid container spacing={2}>
              <Grid item xs={4}>
                <Skeleton variant='text' sx={{ fontSize: '1.5rem', width: '100%' }} />
              </Grid>
              <Grid item xs={4}>
                <Skeleton variant='text' sx={{ fontSize: '1.5rem', width: '100%' }} />
              </Grid>
              <Grid item xs={4}>
                <Skeleton variant='text' sx={{ fontSize: '1.5rem', width: '100%' }} />
              </Grid>
            </Grid>
            <Skeleton variant='text' sx={{ fontSize: '1rem', width: '20%', marginTop: '2rem' }} />
            <Skeleton variant='text' sx={{ fontSize: '1.5rem', width: '50%' }} />
          </FlexiFlowCardInnerContainer>
        </CardContent>
      </StyledFlexFlowCard>
    </>
  );
};
