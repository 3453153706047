import { Box, styled } from '@mui/material';
import { Body2, Caption, ehiTheme } from '@ehi/ui';

export const PaymentSectionBox = styled(Box)`
  display: flex;
  flex-direction: column;
  padding: ${ehiTheme.spacing(3)};
`;

export const BillToItem = styled(Box)`
  padding-top: ${ehiTheme.spacing(2)};
  padding-left: ${ehiTheme.spacing(3)};
  padding-right: ${ehiTheme.spacing(3)};
`;

export const BillToItemsContainer = styled(Box)`
  background-color: ${ehiTheme.ehi.palette.secondary.background};
  margin-top: ${ehiTheme.spacing(3)};
  margin-bottom: ${ehiTheme.spacing(3)};
  padding-bottom: ${ehiTheme.spacing(2)};
`;

export const PaymentDetailsContainer = styled(Box)`
  background-color: ${ehiTheme.ehi.palette.secondary.background};
  margin-top: ${ehiTheme.spacing(0)};
  margin-bottom: ${ehiTheme.spacing(3)};
  padding-bottom: ${ehiTheme.spacing(2)};
  flex-direction: column;
`;

export const PaymentDetailsItem = styled(Box)`
  padding-top: ${ehiTheme.spacing(2)};
  padding-left: ${ehiTheme.spacing(3)};
  padding-right: ${ehiTheme.spacing(3)};
  align-items: flex-start;
  gap: 4px;
`;

export const CardDetailsContainer = styled(Box)`
  display: inline-flex;
  justify-content: space-between;
  align-items: center;
  gap: 16px;
  padding-left: 0;
`;

export const LoyaltyPill = styled(Body2)`
  justify-content: center;
  align-items: center;
  background-color: ${ehiTheme.ehi.palette.success.main};
  color: ${ehiTheme.ehi.palette.success.contrastText};
  padding: ${ehiTheme.spacing(0, 0.5)};
  text-transform: uppercase;
  border-radius: 4px;
  letter-spacing: 1.5px;
  font-size: 10px;
  min-width: 16px;
  height: 16px;
`;

export const ErrorMessageContainer = styled(Box)`
  display: flex;
  align-items: center;
  gap: 8px;
  align-self: stretch;
`;

export const ErrorMessage = styled(Caption)`
  color: ${ehiTheme.ehi.palette.error.main};
  font-feature-settings: 'liga' off, 'clig' off;
  /* other/caption */
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0.4px;
`;

export const RenterChargesContainer = styled(Box)`
  display: flex;
  margin-top: ${ehiTheme.spacing(2)};
  padding: ${ehiTheme.spacing(0, 3)};
  justify-content: flex-end;
  align-items: center;
  gap: 16px;
  align-self: stretch;
`;
