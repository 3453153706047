import { styled, Tab } from '@mui/material';
import { ehiTheme } from '@ehi/ui';

export const FlexiAppBar = styled('div')`
  position: sticky;
  height: 72px;
  width: 100%;
  z-index: 2;
  background-color: #f5f5f5;
  margin-bottom: ${ehiTheme.spacing(2)};
`;

export const FlexiTab = styled(Tab)(({ theme }) => ({
  padding: theme.spacing(0, 1),
  minWidth: '2rem',
  alignSelf: 'baseline',
  flexGrow: 1,
  flexShrink: 1,
  flexBasis: 'fit-content',
}));

export const FlexMenuContainer = styled('div')`
  flex: 1;
  padding: ${ehiTheme.spacing(1, 2.4, 2.5)};
`;
