import { Body2, H6 } from '@ehi/ui';
import { Grid } from '@mui/material';
import { useLocale, useTranslations } from 'components/shared/i18n';
import {
  DynamicGrid,
  EstimatedChargesContent,
  EstimatedChargesTitle,
  EstimatedTotalAmountText,
  EstimatedTotalText,
} from 'components/flexFlow/review/Review.styles';
import { categorizeCharges, getTotalCharges } from 'utils/reviewPageUtils';
import { useAppSelector } from 'redux/hooks';
import { selectCharges, selectPickup, selectReturn } from 'redux/selectors/bookingEditor';
import { getDaysDiff } from 'utils/dateUtils';
import { useMemo, FC } from 'react';
import { DOUBLE_DASH } from 'utils/constants';

export const EstimatedCharges: FC = () => {
  const { t } = useTranslations();
  const { locale } = useLocale();
  const charges = useAppSelector(selectCharges);
  const pickupData = useAppSelector(selectPickup);
  const returnData = useAppSelector(selectReturn);
  const categorizedCharges = useMemo(
    () => (charges ? categorizeCharges(charges, locale) : undefined),
    [charges, locale]
  );

  const lengthOfRental = useMemo(() => {
    const daysDiff = getDaysDiff(pickupData?.dateTime, returnData?.dateTime);
    return daysDiff > 0 ? `${daysDiff}${t('whenWhere.days')}` : DOUBLE_DASH;
  }, [pickupData?.dateTime, returnData?.dateTime, t]);
  const totalCharges = charges?.totalCharges;
  const estimatedTotal = useMemo(
    () => getTotalCharges(locale, totalCharges?.amount, totalCharges?.currencyCode),
    [totalCharges, locale]
  );

  return (
    <EstimatedChargesTitle data-testid={'estimatedChargesSection'}>
      <H6 gutterBottom>{t('common.estimatedCharges')}</H6>
      <EstimatedChargesContent>
        <DynamicGrid container data-testid={'chargesTableHeaders'} spacing={2} pb={2}>
          <Grid container item xs={12} justifyContent='space-between'>
            <Grid item xs={8}>
              <Body2 fontWeight='bold'>{t('review.renterCharges')}</Body2>
            </Grid>
            <Grid item xs={4}>
              <Body2 fontWeight='bold' align='right'>
                {t('review.total')}
              </Body2>
            </Grid>
          </Grid>
        </DynamicGrid>
        <DynamicGrid container data-testid={'vehicleCharges'} item xs={12} spacing={2} py={2}>
          <Grid item xs={6}>
            <Body2>{t('common.vehicle').toUpperCase()}</Body2>
          </Grid>
          <Grid item xs={3}>
            <Body2 align='right'>{lengthOfRental}</Body2>
          </Grid>
          <Grid item xs={3}>
            <Body2 align='right'>{categorizedCharges?.vehicleChargesTotal ?? DOUBLE_DASH}</Body2>
          </Grid>
        </DynamicGrid>
        <DynamicGrid container data-testid={'extrasCharges'} item xs={12} spacing={2} py={2}>
          <Grid item xs={8}>
            <Body2>{t('review.extras').toUpperCase()}</Body2>
          </Grid>
          <Grid item xs={4}>
            <Body2 align='right'>{categorizedCharges?.extrasChargesTotal ?? DOUBLE_DASH}</Body2>
          </Grid>
        </DynamicGrid>
        <DynamicGrid container data-testid={'taxesAndFeesCharges'} item xs={12} spacing={2} py={2}>
          <Grid item xs={8}>
            <Body2>{t('review.taxesAndFeesReview').toUpperCase()}</Body2>
          </Grid>
          <Grid item xs={4}>
            <Body2 align='right'>{categorizedCharges?.taxAndFeesChargesTotal ?? DOUBLE_DASH}</Body2>
          </Grid>
        </DynamicGrid>
        <Grid container data-testid={'estimatedTotal'} item xs={12} justifyContent='flex-end' sx={{ mt: 3 }}>
          <Grid item>
            <EstimatedTotalText>{t('review.estimatedTotalReview')}</EstimatedTotalText>
            <EstimatedTotalAmountText>{estimatedTotal}</EstimatedTotalAmountText>
          </Grid>
        </Grid>
      </EstimatedChargesContent>
    </EstimatedChargesTitle>
  );
};
