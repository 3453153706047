import { FC } from 'react';
import { useTranslations } from 'components/shared/i18n';
import DynamicHelmet from 'components/shared/helmet/DynamicHelmet';
import { Outlet } from 'react-router-dom';
import { ResActionsProvider } from 'context/resActions/ResActionsContext';
import { StyledModifyIconButton } from 'app/router/Router.styles';
import { VehicleListProvider } from 'context/vehicleList/VehicleListContext';

export const ModifyRouter: FC = () => {
  const { t } = useTranslations();
  return (
    <ResActionsProvider label={t('common.save')} icon={<StyledModifyIconButton data-testid={'modifyRouterIcon'} />}>
      <VehicleListProvider>
        <DynamicHelmet title={t('common.modifyReservation')} />
        <Outlet />
      </VehicleListProvider>
    </ResActionsProvider>
  );
};
