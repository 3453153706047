import { FC, useEffect } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { RadioGroup, FormControlLabel, Radio } from '@mui/material';
import { Dialog } from 'components/shared/ui/dialogs/Dialog';
import { Body1, EhiButtonProps } from '@ehi/ui';
import { StyledDialogContent } from 'components/flexFlow/driver/Driver.styles';
import { useTranslations } from 'components/shared/i18n';
import { SELECTION_OPTION } from 'components/flexFlow/driver/driverForm/driverFormUtils';

type Option = {
  label: string;
  value: string;
};

type ConfirmationDialogProps = {
  open: boolean;
  onConfirm: (selection: string) => void;
  onCancel: () => void;
  title: string;
  description: string;
  options: Option[];
  defaultSelection?: string;
};

export const ConfirmationDialog: FC<ConfirmationDialogProps> = ({
  open,
  onConfirm,
  onCancel,
  title,
  description,
  options,
  defaultSelection,
}) => {
  const { t } = useTranslations();
  const { control, watch, reset } = useForm<{ selectedOption: string | null }>({
    defaultValues: { [SELECTION_OPTION]: defaultSelection ?? null },
  });

  const selectedOption = watch(SELECTION_OPTION);

  useEffect(() => {
    if (open) {
      reset({ selectedOption: defaultSelection ?? null });
    }
  }, [open, defaultSelection, reset]);

  const handleConfirm = () => {
    if (selectedOption) {
      onConfirm(selectedOption);
      reset({ [SELECTION_OPTION]: defaultSelection ?? null });
    }
  };

  const handleCancel = () => {
    reset({ [SELECTION_OPTION]: defaultSelection ?? null });
    onCancel();
  };

  return (
    <Dialog
      open={open}
      fullWidth
      maxWidth={'sm'}
      onClose={handleCancel}
      title={title}
      actions={{
        primaryAction: {
          label: t('common.confirm'),
          onClick: handleConfirm,
          overrideButtonProps: {
            color: 'primary',
            variant: 'contained',
            disabled: !selectedOption,
          } as EhiButtonProps,
        },
        secondaryAction: {
          label: t('common.cancel'),
          onClick: handleCancel,
        },
      }}
      a11yKey='confirmation-dialog'>
      <StyledDialogContent data-testid='dialog-content'>
        <Body1 data-testid='dialog-description'>{description}</Body1>
        <Controller
          name={SELECTION_OPTION}
          control={control}
          render={({ field }) => (
            <RadioGroup
              {...field}
              onChange={(e) => field.onChange(e.target.value)}
              data-testid='radio-group'
              sx={{ alignItems: 'flex-start' }}>
              {options.map((option) => (
                <FormControlLabel
                  key={option.value}
                  value={option.value}
                  control={<Radio data-testid={`radio-${option.value}`} />}
                  label={option.label}
                />
              ))}
            </RadioGroup>
          )}
        />
      </StyledDialogContent>
    </Dialog>
  );
};
