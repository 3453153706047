import { ReservationSummaryCard } from 'components/resSummary/ReservationSummaryCard';
import CardContent from '@mui/material/CardContent';
import { Grid } from '@mui/material';
import { Caption2 } from 'components/shared/ui/styles/Typography.styles';
import { Body1 } from '@ehi/ui';
import { useTranslations } from 'components/shared/i18n';
import { RouterPaths } from 'app/router/RouterPaths';
import { LoadingState } from 'components/shared/ui/spinner/loadableView/LoadableViewTypes';
import { parseUrn } from 'utils/urnUtils';
import { useAppSelector } from 'redux/hooks';
import { selectPayers } from 'redux/selectors/bookingEditor';
import { FC } from 'react';

export const PaymentSummary: FC = () => {
  const { t } = useTranslations();
  const payers = useAppSelector(selectPayers);
  return (
    <ReservationSummaryCard
      title={t('reservationSummary.payment')}
      loading={LoadingState.SUCCESS}
      path={RouterPaths.Payment}
      data-testid='paymentSummaryCard'>
      <CardContent>
        <Grid container direction={'column'}>
          <Grid item xs>
            <Caption2>{t('payment.paymentMethod')}</Caption2>
            <Body1 bold>
              {parseUrn(payers?.person?.paymentMethod) ? parseUrn(payers?.person?.paymentMethod) : '--'}
            </Body1>
          </Grid>
        </Grid>
      </CardContent>
    </ReservationSummaryCard>
  );
};
