import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import { FC } from 'react';
import { useController } from 'react-hook-form';
import { useTranslations } from 'components/shared/i18n';
import { DO_NOT_RENT, RENT } from 'components/flexFlow/driver/driverSearch/renterWarning/RenterWarningTypes';

type WarningOptionsRadioGroupProps = {
  name: string;
};

export const WarningOptionsRadioGroup: FC<WarningOptionsRadioGroupProps> = ({ name }) => {
  const { t } = useTranslations();
  const { field } = useController({ name });

  return (
    <RadioGroup row {...field}>
      <FormControlLabel
        value={RENT}
        control={<Radio color='primary' />}
        label={t('driver.warnings.rent')}
        labelPlacement='end'
      />
      <FormControlLabel
        value={DO_NOT_RENT}
        control={<Radio color='primary' />}
        label={t('driver.warnings.doNotRent')}
        labelPlacement='end'
      />
    </RadioGroup>
  );
};
