import { createElement, FC } from 'react';
import { FlexComponentProps } from './FlexFlowTypes';
import { useSaveOnNavigateHelper } from 'hooks/useSaveOnNavigateHelper';

export const FlexComponent: FC<FlexComponentProps> = ({ component, previous, next }: FlexComponentProps) => {
  const { saveOnNavigate } = useSaveOnNavigateHelper();
  return createElement(component, {
    onPrevious: previous ? () => saveOnNavigate(previous, { replace: true }) : undefined,
    onNext: next ? () => saveOnNavigate(next, { replace: true }) : undefined,
  });
};
