import { getDefaultAxiosInstance } from 'components/shared/axios/axiosConfiguration';
import { CardBrand } from 'services/payment/paymentReferenceTypes';

const PAYMENT_REFERENCE_ENDPOINT = '/reference/payment';
const CARD_BRAND_ENDPOINT = '/card/brand';

export const retrieveAllCardBrands = async (): Promise<CardBrand[]> => {
  const axiosInstance = getDefaultAxiosInstance();
  const response = await axiosInstance.get(`${PAYMENT_REFERENCE_ENDPOINT}${CARD_BRAND_ENDPOINT}`, {
    genericUrl: `${PAYMENT_REFERENCE_ENDPOINT}${CARD_BRAND_ENDPOINT}`,
  });
  return response.data;
};
