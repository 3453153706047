import InputAdornment from '@mui/material/InputAdornment';
import { FC } from 'react';
import { StyledIconButton } from 'components/shared/ui/InputIconButton/InputIconButton.styles';
import { InputIconButtonProps } from './InputIconButtonTypes';

export const InputIconButton: FC<InputIconButtonProps> = ({ onClick, icon, position = 'end', label, disabled }) => (
  <InputAdornment position={position}>
    <StyledIconButton size='medium' aria-label={label} onClick={onClick} disabled={disabled}>
      {icon}
    </StyledIconButton>
  </InputAdornment>
);
