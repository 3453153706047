import { useAlert } from 'components/shared/alert/AlertContext';
import { useUpdateAndRefreshEditor } from 'hooks/bookingEditor/useUpdateAndRefreshEditor';
import { useAppSelector } from 'redux/hooks';
import { selectAddons, selectBookingEditorId } from 'redux/selectors/bookingEditor';
import { ServiceResultType } from 'services/types/ServiceResultTypes';
import { updateProtections } from 'services/booking/bookingService';
import { formatProtection } from 'utils/peoUtils';

import {
  BaseProtection,
  ProtectionFormModel,
  ProtectionsFormFields,
  ProtectionsItemFields,
} from 'components/flexFlow/peo/peoTypes';

export type SaveProtections = {
  saveProtectionAndRefreshEditor: (protectionModal: ProtectionFormModel) => Promise<ServiceResultType>;
  selectedProtections: BaseProtection[];
  availableProtections: BaseProtection[];
};

export const useSaveProtections = (): SaveProtections => {
  const bookingEditorId = useAppSelector(selectBookingEditorId);
  const addOns = useAppSelector(selectAddons);
  const { showAlert } = useAlert();
  const { updateAndRefresh } = useUpdateAndRefreshEditor();

  const availableProtections = Object.entries(addOns?.protections?.available || []).flatMap(
    ([category, protectionsForCategory]) => {
      return protectionsForCategory.map((availableProtections) => {
        if (!availableProtections.selectability || !availableProtections.rate) {
          throw new Error('Missing required protection info');
        }
        return formatProtection(availableProtections, category);
      });
    }
  );
  const selectedProtections = (addOns?.protections?.selected || []).map((item) => {
    return formatProtection(item);
  });

  const saveProtectionAndRefreshEditor = async (protectionModal: ProtectionFormModel) => {
    const protectionUpdates = protectionModal[ProtectionsFormFields.Items]
      ?.filter((protectionItemModel) => protectionItemModel[ProtectionsItemFields.Checked])
      ?.map((protectionItemModel) => {
        const item = protectionItemModel[ProtectionsItemFields.Item];
        return { type: item.type };
      });

    const { errors } = await updateAndRefresh(() => updateProtections(bookingEditorId, protectionUpdates));
    if (errors) {
      showAlert({ responseMessages: errors });
    }
    return { success: !errors, errors: errors };
  };

  return {
    saveProtectionAndRefreshEditor,
    availableProtections,
    selectedProtections,
  };
};
