import { FC } from 'react';
import { CardProps } from 'components/shared/ui/card/CardTypes';
import {
  StyledCard,
  StyledCardInnerContainer,
  StyledFlexFlowCard,
  StyledListItemCard,
} from 'components/shared/ui/card/Card.styles';
import { CardHeader } from 'components/shared/ui/card/CardHeader';
import { LoadableView } from 'components/shared/ui/spinner/loadableView/LoadableView';
import NetworkError from 'components/shared/errors/NetworkError';
import { LoadingState } from 'components/shared/ui/spinner/loadableView/LoadableViewTypes';
import { FullScreenSpinner } from 'components/shared/ui/spinner/FullScreenSpinner';

export const Card: FC<CardProps> = ({ title, titleAdornment, headerActions, children, ...rest }) => {
  return (
    <StyledCard variant={'outlined'} {...rest} role={'region'} aria-label={title}>
      {(title || headerActions) && (
        <CardHeader title={title} titleAdornment={titleAdornment} headerActions={headerActions} {...rest} />
      )}
      {children}
    </StyledCard>
  );
};

export const ListItemCard: FC<CardProps> = ({ title, children, ...rest }) => {
  return (
    <StyledListItemCard {...rest} aria-label={title}>
      {children}
    </StyledListItemCard>
  );
};

export const FlexFlowCard: FC<CardProps> = ({ loadingState, title, titleAdornment, children, ...rest }) => {
  return (
    <LoadableView
      loadingComponent={<FullScreenSpinner />}
      errorComponent={<NetworkError />}
      state={loadingState ?? LoadingState.SUCCESS}>
      <StyledFlexFlowCard variant={'outlined'} {...rest} role={'region'} aria-label={title} id={'scrolling-view'}>
        {title && <CardHeader title={title} titleAdornment={titleAdornment} {...rest} />}
        {children}
      </StyledFlexFlowCard>
    </LoadableView>
  );
};

export const FlexiFlowCardInnerContainer: FC<CardProps> = ({
  title,
  titleAdornment,
  children,
  headerActions,
  ...rest
}) => {
  return (
    <StyledCardInnerContainer variant={'outlined'} {...rest} role={'region'} aria-label={title}>
      {title && <CardHeader title={title} titleAdornment={titleAdornment} headerActions={headerActions} {...rest} />}
      {children}
    </StyledCardInnerContainer>
  );
};
