import { FC, useEffect, ReactNode } from 'react';
import ErrorWrapper from 'components/shared/errors/ErrorWrapper';
import { handleError } from 'utils/errorUtils';
import { useRouteError } from 'react-router-dom';

/**
 * There are limitations of error boundary in React. Error boundaries can't catch errors for.
 * Event handlers
 * Asynchronous code (e.g. setTimeout or callbacks)
 * Server side rendering
 * Errors thrown in the error boundary itself (rather than its children)
 **/
export const ReservationErrorBoundary: FC<{ children?: ReactNode }> = ({ children }) => {
  const error = useRouteError();

  useEffect(() => {
    if (error) {
      handleError(error);
    }
    const handleUnhandledError = (event: ErrorEvent) => {
      handleError(event.error);
    };

    const handleUnhandledRejection = (event: PromiseRejectionEvent) => {
      handleError(event.reason);
    };
    /**
     * This will catch any unhandled error that happens during rendering or while event handlers are triggered
     */
    window.addEventListener('error', handleUnhandledError);

    /**
     * This will catch any unhandled error that happens during async code or unhandled promise rejections during render
     */
    window.addEventListener('unhandledrejection', handleUnhandledRejection);

    // Clean up the event listener when the component is unmounted
    return () => {
      window.removeEventListener('error', handleUnhandledError);
      window.removeEventListener('unhandledrejection', handleUnhandledRejection);
    };
  }, [error]); // Empty dependency array ensures this effect runs once during component mount

  return (error as Error)?.message || (error as { statusText?: string })?.statusText ? (
    <ErrorWrapper />
  ) : (
    <>{children}</>
  );
};
