import { Box, Card, Grid, Skeleton } from '@mui/material';
import { HeaderName, HeaderValue, StyledPaper } from 'components/customerHeader/CustomerHeader.styles';
import { FlexiAppBar } from 'components/shared/flexiFlow/FlexiFlowMenu.styles';
import { FlexFlowCardSkeleton } from './FlexFlowCardSkeleton';
import { FC } from 'react';

export const FlexFlowLoadingSkeleton: FC = () => {
  return (
    <Box data-testid={'loadingSkeleton'}>
      <StyledPaper>
        <Card>
          <Grid container direction='row' alignItems={'center'}>
            <Grid container item xs={6} alignItems={'center'} spacing={1}>
              <Grid item>
                <Skeleton variant='circular' width={40} height={40} />
              </Grid>
              <Grid item>
                <HeaderName>
                  <Skeleton variant='text' sx={{ width: '200px' }} />
                </HeaderName>
              </Grid>
            </Grid>
            <Grid container item xs={6} justifyContent={'flex-end'} spacing={3}>
              <Grid item>
                <HeaderName sx={{ color: 'black' }}>
                  <Skeleton variant='text' sx={{ width: '100px' }} />
                </HeaderName>
                <HeaderValue>
                  <Skeleton variant='text' sx={{ width: '100px' }} />
                </HeaderValue>
              </Grid>
              <Grid item sx={{ textAlign: 'right' }}>
                <HeaderName>
                  <Skeleton variant='text' sx={{ width: '100px' }} />
                </HeaderName>
                <HeaderValue sx={{ color: 'text.secondary' }}>
                  <Skeleton variant='text' sx={{ width: '100px' }} />
                </HeaderValue>
              </Grid>
            </Grid>
          </Grid>
        </Card>
      </StyledPaper>
      <Box>
        <div style={{ display: 'flex' }}>
          <div style={{ flex: '1', padding: '20px', paddingTop: 0, marginTop: '10px' }}>
            <FlexiAppBar>
              <Grid container direction={'row'} justifyContent={'space-between'}>
                {Array.from({ length: 6 }).map((_elem: any, index) => (
                  <Grid item xs={2} key={'skeleton' + index}>
                    <Skeleton variant='circular' width={24} height={24} sx={{ margin: 'auto' }} />
                    <Skeleton
                      variant='rectangular'
                      width={'80%'}
                      height={20}
                      sx={{ margin: 'auto', marginTop: '10px' }}
                    />
                  </Grid>
                ))}
              </Grid>
            </FlexiAppBar>
          </div>
        </div>
      </Box>
      <FlexFlowCardSkeleton />
    </Box>
  );
};
