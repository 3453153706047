import i18n, { TypeOptions } from 'i18next';
import { initReactI18next } from 'react-i18next';
import Backend from 'i18next-http-backend';
import { Iso2Locales, loadLocaleCookie } from '@ehi/location';
import enUsTranslationJson from 'locales/en-US/translation.json';

export type DeepKeyOf<JsonObj extends object, Separator extends string = '.'> = {
  [JsonKey in keyof JsonObj & (string | number)]: JsonObj[JsonKey] extends object
    ? `${JsonKey}${Separator}${DeepKeyOf<JsonObj[JsonKey]>}`
    : JsonKey;
}[keyof JsonObj & (string | number)];

type PluralSuffix = 'zero' | 'one' | 'two' | 'few' | 'many' | 'other';
export type WithoutPluralSuffix<K> = K extends `${infer B}_${PluralSuffix}` ? B : K;

export type TranslationKey = WithoutPluralSuffix<DeepKeyOf<typeof enUsTranslationJson, TypeOptions['keySeparator']>>;

const getDefaultLanguage = (): string => {
  return loadLocaleCookie() ?? navigator.language;
};

i18n
  .use(Backend)
  .use(initReactI18next)
  .init({
    lng: getDefaultLanguage(),
    fallbackLng: {
      // TODO: temp until we start moving things to proper localization files
      [Iso2Locales.frUS]: [Iso2Locales.frCA, Iso2Locales.enUS],
      default: [Iso2Locales.enUS],
    },
    resources: {
      [Iso2Locales.enUS]: { translation: enUsTranslationJson },
    },
    keySeparator: '.',
    returnNull: false,
    load: 'currentOnly',
    react: {
      useSuspense: false,
    },
    interpolation: {
      escapeValue: false,
    },
  });

declare module 'i18next' {
  interface CustomTypeOptions {
    defaultNS: 'translation';
    resources: {
      translation: typeof enUsTranslationJson;
    };
  }
}

export default i18n;
