import { FC } from 'react';

export const QuickResIframeContainer: FC = () => {
  return (
    <iframe
      src='http://localhost:3000/iframe/quick-res'
      height={'100%'}
      width={'100%'}
      data-testid={'iframe-container'}
    />
  );
};
