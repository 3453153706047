import { Dispatch, FC, MutableRefObject, SetStateAction, useCallback, useState } from 'react';
import {
  DriverFormFields,
  DriverFormValues,
  DriverType,
  ExtendedTransactionalProfileBase,
} from 'components/flexFlow/driver/driverForm/DriverFormTypes';
import { FormProvider, useForm } from 'react-hook-form';
import { DriverSection } from 'components/flexFlow/driver/driverForm/formSection/DriverSection';
import { LicenseSection } from 'components/flexFlow/driver/driverForm/formSection/LicenseSection';
import { ContactSection } from 'components/flexFlow/driver/driverForm/formSection/ContactSection';
import { MarginWrapper } from 'components/shared/ui/styles/Global.styles';
import { DividerWithFullWidth } from 'components/shared/ui/styles/Divider.styles';
import { ehiTheme } from '@ehi/ui';
import { ProgressOverlay } from 'components/shared/ui/spinner/ProgressOverlay';
import {
  ADD,
  driverFormInitialValues,
  driverFormValidationSchema,
  isValidFullProfile,
  parseContactName,
  REMOVE,
  RETAIL,
} from 'components/flexFlow/driver/driverForm/driverFormUtils';
import {
  createDriverProfile,
  modifyContactInformation,
  modifyDriverProfile,
} from 'services/renter/driverProfile/driverProfileService';
import {
  transformToCreateDriverProfileRequest,
  transformToCreateTransactionalProfileRequest,
  transformToModifyContactInfoRequest,
  transformToModifyDriverProfileRequest,
} from 'components/shared/uiModels/driver/driverTransformer';
import { safelyCatchError } from 'utils/errorUtils';
import { getAppConfigCache } from 'services/appConfig/appConfigService';
import {
  createTransactionalProfile,
  getTransactionalProfile,
  updateTransactionalProfile,
} from 'services/renter/transactionalProfile/transactionalProfileService';
import { DriverData, RenterTypes } from 'components/shared/uiModels/driver/driverDataTypes';
import { AdditionalDriver, Renter, RateSource } from 'services/booking/bookingTypes';
import {
  associateRenterToReservationEditor,
  updateAdditionalDrivers,
  modifyRateSource,
} from 'services/booking/bookingService';
import { useUpdateAndRefreshEditor } from 'hooks/bookingEditor/useUpdateAndRefreshEditor';
import { useAlert } from 'components/shared/alert/AlertContext';
import { useAppSelector } from 'redux/hooks';
import {
  selectAdditionalDrivers,
  selectBookingEditorId,
  selectContact,
  selectDriverProfileRenter,
} from 'redux/selectors/bookingEditor';
import { useTranslations } from 'components/shared/i18n';
import { useYupValidationResolver } from 'components/shared/forms/useYupValidationResolver';
import { ResponseMessage } from 'services/types/ResponseMessageTypes';
import { DriverProfile } from 'services/renter/driverProfile/driverProfileTypes';
import { TransactionalProfileResponseContent } from 'services/renter/transactionalProfile/transactionalProfileTypes';
import { Alert } from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';
import { StyledDriverBanner } from 'components/flexFlow/driver/driverForm/DriverForm.styles';
import { parseUrn } from 'utils/urnUtils';
import { EMPTY_VALUE } from 'utils/constants';
import { ConfirmationDialog } from 'components/flexFlow/driver/editDriver/ConfirmationDialog';
import { useAccountDetails } from 'services/businessAccount/useAccountDetails';
import { TranslationKey } from 'components/shared/i18n/i18n';

export type CreateDriverFormProps = {
  onClose: (driverModified: boolean) => void;
  formRef?: MutableRefObject<{ handleSubmit: () => Promise<void> } | null>;
  driver?: DriverData;
  isUpdatingPrimaryDriver?: boolean;
  changePrimaryDriverDialogIsOpen: boolean;
  setChangePrimaryDriverDialogIsOpen: Dispatch<SetStateAction<boolean>>;
};

export const DriverForm: FC<CreateDriverFormProps> = ({
  onClose,
  formRef,
  driver,
  isUpdatingPrimaryDriver = false,
  changePrimaryDriverDialogIsOpen,
  setChangePrimaryDriverDialogIsOpen,
}) => {
  const { t } = useTranslations();
  const appConfig = getAppConfigCache();
  const bookingEditorId = useAppSelector(selectBookingEditorId);
  const driverProfileRenter = useAppSelector(selectDriverProfileRenter);
  const additionalDrivers = useAppSelector(selectAdditionalDrivers);
  const contact = useAppSelector(selectContact);
  const [driverData, setDriverData] = useState<ExtendedTransactionalProfileBase | undefined>(undefined);
  const { updateAndRefresh } = useUpdateAndRefreshEditor();
  const { showAlert } = useAlert();
  const [loading, setLoading] = useState(false);
  const resolver = useYupValidationResolver(driverFormValidationSchema(t));
  const { rateSourceInfo } = useAccountDetails();
  const formMethods = useForm<DriverFormValues>({
    resolver: resolver,
    defaultValues: driverFormInitialValues(driver),
  });

  const invokeAlert = useCallback(
    async (description: string | JSX.Element) => {
      setLoading(false);
      await showAlert({
        description: description ?? EMPTY_VALUE,
      });
    },
    [showAlert]
  );

  const handleError = useCallback(
    async (errors: ResponseMessage[] | undefined, message: TranslationKey) => {
      if (errors) {
        await invokeAlert(`${t(message)}: ${errors?.[0]?.localizedMessage || ''}`);
      } else {
        setLoading(false);
        onClose(false);
      }
    },
    [invokeAlert, onClose, t]
  );

  const handleUpdateEditor = useCallback(
    async (driverProfile: DriverProfile, isTransactionProfile?: boolean, updatePrimaryDriver = false) => {
      if (!driverProfile.urn) {
        await invokeAlert(t('driver.addDriverError'));
      } else {
        if (!driverProfileRenter || (isUpdatingPrimaryDriver && updatePrimaryDriver)) {
          const requestBody = {
            type: isTransactionProfile ? RenterTypes.TransactionalProfile : RenterTypes.DriverProfile,
            profile: driverProfile.urn,
          } as Renter;

          const rateSourceRequestBody: RateSource = {
            type: RETAIL,
          };

          const { errors } = await updateAndRefresh(async () => {
            await associateRenterToReservationEditor(bookingEditorId, requestBody as Renter);
            if (rateSourceInfo) await modifyRateSource(bookingEditorId, rateSourceRequestBody);
          });

          await handleError(errors, 'driver.addDriverError');
        } else {
          const currentAdditionalDrivers = additionalDrivers && additionalDrivers.length > 0 ? additionalDrivers : [];
          let requestBody: AdditionalDriver[];
          const newAdditionalDriverData: AdditionalDriver = {
            name: { given: driverProfile.name?.givenName, surname: driverProfile.name?.surname ?? EMPTY_VALUE },
            profile: driverProfile.urn,
          };
          if (driver?.additionalDriverIndex !== undefined) {
            const newAdditionalDrivers = [...currentAdditionalDrivers];
            newAdditionalDrivers.splice(driver.additionalDriverIndex, 1, newAdditionalDriverData);
            requestBody = newAdditionalDrivers;
          } else {
            requestBody = [...currentAdditionalDrivers, newAdditionalDriverData];
          }

          const { errors } = await updateAndRefresh(() => updateAdditionalDrivers(bookingEditorId, requestBody));
          await handleError(errors, 'driver.addAdditionalDriverError');
        }
      }
    },
    [
      invokeAlert,
      t,
      driverProfileRenter,
      isUpdatingPrimaryDriver,
      updateAndRefresh,
      handleError,
      bookingEditorId,
      rateSourceInfo,
      additionalDrivers,
      driver?.additionalDriverIndex,
    ]
  );

  const handleCreatingProfile = useCallback(
    async (values: DriverFormValues) => {
      setLoading(true);
      try {
        if (isValidFullProfile(values)) {
          const driverProfile: DriverProfile = await createDriverProfile(
            transformToCreateDriverProfileRequest(values, appConfig?.defaultEhiDatabase ?? '')
          );
          if (!isUpdatingPrimaryDriver) {
            await handleUpdateEditor(driverProfile);
          } else {
            setLoading(false);
            setDriverData(driverProfile);
            setChangePrimaryDriverDialogIsOpen(true);
            return;
          }
        } else {
          const transactionalProfile: TransactionalProfileResponseContent = await createTransactionalProfile(
            transformToCreateTransactionalProfileRequest(values, appConfig?.defaultEhiDatabase ?? '')
          );
          if (isUpdatingPrimaryDriver) {
            setLoading(false);
            setDriverData(transactionalProfile);
            setChangePrimaryDriverDialogIsOpen(true);
            return;
          }
          const driverProfile: DriverProfile = {
            name: {
              surname: transactionalProfile.personalInformation?.name?.surname ?? '',
              givenName: transactionalProfile.personalInformation?.name?.givenName ?? '',
            },
            urn: transactionalProfile.urn,
          };

          await handleUpdateEditor(driverProfile, true);
        }
      } catch (error) {
        const ehiErrorsResponse = safelyCatchError(error);
        if (ehiErrorsResponse?.errors) {
          await showAlert({ responseMessages: ehiErrorsResponse?.errors });
        }
        setLoading(false);
      }
    },
    [
      appConfig?.defaultEhiDatabase,
      handleUpdateEditor,
      isUpdatingPrimaryDriver,
      setChangePrimaryDriverDialogIsOpen,
      showAlert,
    ]
  );

  const handleModifyingProfile = useCallback(
    async (values: DriverFormValues) => {
      if (Object.keys(formMethods.formState.dirtyFields).length) {
        try {
          setLoading(true);
          if (values[DriverFormFields.DriverType] === DriverType.LOYALTY_DRIVER_PROFILE) {
            await modifyContactInformation(
              parseUrn(driver?.urn),
              transformToModifyContactInfoRequest(values, appConfig?.defaultEhiDatabase ?? '')
            );
            onClose(true);
          } else if (
            values[DriverFormFields.DriverType] === DriverType.DRIVER_PROFILE ||
            values[DriverFormFields.DriverType] === DriverType.DRIVER_PROFILE_DNR
          ) {
            await modifyDriverProfile(
              parseUrn(driver?.urn),
              transformToModifyDriverProfileRequest(values, appConfig?.defaultEhiDatabase ?? '')
            );
            onClose(true);
          } else if (driver?.urn) {
            await getTransactionalProfile(parseUrn(driver.urn)).then(async (profile) => {
              await updateTransactionalProfile(
                profile.uuid ?? EMPTY_VALUE,
                transformToCreateTransactionalProfileRequest(values, appConfig?.defaultEhiDatabase ?? ''),
                parseUrn(profile.lockContextUrn)
              )
                .then(() => {
                  onClose(true);
                })
                .catch(async (error) => {
                  const ehiErrorsResponse = safelyCatchError(error);
                  if (ehiErrorsResponse?.errors) {
                    await showAlert({
                      responseMessages: ehiErrorsResponse?.errors,
                    });
                  }
                });
            });
          }
        } catch (error) {
          const ehiErrorsResponse = safelyCatchError(error);
          if (ehiErrorsResponse?.errors) {
            await showAlert({
              responseMessages: ehiErrorsResponse?.errors,
            });
          }
        } finally {
          setLoading(false);
        }
      } else {
        onClose(false);
      }
    },
    [appConfig?.defaultEhiDatabase, driver?.urn, formMethods.formState.dirtyFields, onClose, showAlert]
  );

  if (formRef) {
    formRef.current = {
      handleSubmit: !driver?.urn
        ? formMethods.handleSubmit(handleCreatingProfile)
        : formMethods.handleSubmit(handleModifyingProfile),
    };
  }

  const getBannerMessage = (driverType: DriverType | undefined) => {
    if (driverType === DriverType.DRIVER_PROFILE || driverType === DriverType.DRIVER_PROFILE_DNR) {
      return t('driver.driverProfileBanner');
    } else if (driverType === DriverType.LOYALTY_DRIVER_PROFILE) {
      return t('driver.loyaltyDriverBanner');
    }
    return undefined;
  };

  const handleConfirmChangePrimaryDriver = async (selection: string) => {
    setLoading(true);
    setChangePrimaryDriverDialogIsOpen(false);
    const isTransactionProfile = !driverData?.legalIdentifications;
    try {
      if (selection === REMOVE && driverData) {
        await handleUpdateEditor(driverData, isTransactionProfile, true);
      } else if (driverProfileRenter && driverData) {
        const { given, surname } = parseContactName(contact?.name ?? '');
        await handleUpdateEditor(driverData, isTransactionProfile, true);

        const currentAdditionalDrivers = additionalDrivers && additionalDrivers.length > 0 ? additionalDrivers : [];

        const requestBody = [
          ...currentAdditionalDrivers,
          {
            name: { given: given ?? '', surname: surname ?? '' },
            profile: driverProfileRenter.profile,
          },
        ];

        const { errors } = await updateAndRefresh(() => updateAdditionalDrivers(bookingEditorId, requestBody));
        await handleError(errors, 'driver.addAdditionalDriverError');
      }
    } catch (error) {
      const ehiErrorsResponse = safelyCatchError(error);
      if (ehiErrorsResponse?.errors) {
        await handleError(ehiErrorsResponse.errors, 'driver.updateDriverError');
      }
    } finally {
      setLoading(false);
    }
  };

  const handleCancelChangePrimaryDriver = () => {
    setChangePrimaryDriverDialogIsOpen(false);
  };

  return (
    <MarginWrapper data-testid={'driverForm'}>
      <FormProvider {...formMethods}>
        {getBannerMessage(formMethods.getValues(DriverFormFields.DriverType)) && (
          <StyledDriverBanner data-testid={'modifyDriverBanner'}>
            <Alert
              severity='info'
              sx={{
                border: `1px solid #6A3E9D80`,
                display: 'flex',
                alignItems: 'center',
              }}
              icon={<InfoIcon />}>
              {getBannerMessage(formMethods.getValues(DriverFormFields.DriverType))}
            </Alert>
          </StyledDriverBanner>
        )}
        <DriverSection />
        <DividerWithFullWidth style={{ borderColor: ehiTheme.palette.divider }} />
        <LicenseSection />
        <DividerWithFullWidth style={{ borderColor: ehiTheme.palette.divider }} />
        <ContactSection />
      </FormProvider>
      <ProgressOverlay inProgress={loading} />
      {changePrimaryDriverDialogIsOpen && (
        <ConfirmationDialog
          open={changePrimaryDriverDialogIsOpen}
          onConfirm={handleConfirmChangePrimaryDriver}
          onCancel={handleCancelChangePrimaryDriver}
          title={isUpdatingPrimaryDriver ? t('driver.changePrimaryDriver') : t('driver.makePrimaryDriver')}
          description={t('driver.additionalDrivers.changePrimaryPrompt')}
          options={[
            { label: t('driver.additionalDrivers.makeAdditionalDriver'), value: ADD },
            { label: t('driver.removeFromReservation'), value: REMOVE },
          ]}
        />
      )}
    </MarginWrapper>
  );
};
