import { FC, useMemo } from 'react';
import { Grid } from '@mui/material';
import { useAppSelector } from 'redux/hooks';
import { selectVehicleClassSelection } from 'redux/selectors/bookingEditor';
import { Body1, Caption, ehiTheme } from '@ehi/ui';
import { useTranslations } from 'components/shared/i18n';
import { useVehicleContentQuery } from 'services/vehicleContent/vehicleContentQueries';
import { useVehicleRates } from 'services/booking/useVehicleRates';
import { transformVehicleListFromApiData } from 'components/shared/uiModels/vehicle/vehicleTransformer';
import { Vehicle } from 'components/shared/uiModels/vehicle/vehicleDataTypes';
import {
  StyledBox,
  StyledGridContainer,
  VehicleSpecGridItem,
  VehicleSpecText,
} from 'components/flexFlow/review/Review.styles';
import AutoTransmissionDarkIcon from 'images/autoTransmissionDarkIcon.svg';
import { getAutoTransmissionDescription, getDistanceAllotmentText } from 'utils/vehicleUtils';
import { Person as PassengerIcon, WorkOutline as LuggageIcon } from '@mui/icons-material';
import { Caption2 } from 'components/shared/ui/styles/Typography.styles';
import { parseUrn } from 'utils/urnUtils';
import { Card } from 'components/shared/ui/card/Card';
import { ProgressOverlay } from 'components/shared/ui/spinner/ProgressOverlay';
import { VehicleFeatures } from 'components/flexFlow/vehicle/VehicleFeatures';

export const VehicleSection: FC = () => {
  const { t } = useTranslations();
  const vehicleClass = useAppSelector(selectVehicleClassSelection);
  const { data: vehicleContent, isFetching: isVehicleContentLoading } = useVehicleContentQuery();
  const { vehicleRates, isLoading: isVehicleRatesLoading } = useVehicleRates();

  const vehicles = useMemo(() => {
    if (!(isVehicleContentLoading || isVehicleRatesLoading)) {
      return transformVehicleListFromApiData(vehicleContent, vehicleRates);
    }
    return [];
  }, [isVehicleContentLoading, isVehicleRatesLoading, vehicleContent, vehicleRates]);

  const selectedVehicle = useMemo(() => {
    return vehicles.find((vehicle: Vehicle) => vehicle.vehicleClass === parseUrn(vehicleClass?.reserved));
  }, [vehicleClass?.reserved, vehicles]);

  return (
    <Card data-testid='vehicleSection' style={{ margin: ehiTheme.spacing(2, 0, 2, 0), padding: 0 }}>
      {selectedVehicle ? (
        <StyledBox>
          <StyledGridContainer>
            <Grid item xs={2} sm={2} display={'flex'} justifyContent={'center'}>
              <img
                data-testid='vehicleImage'
                src={selectedVehicle.imageKey}
                alt={''}
                height={'100%'}
                width={'100%'}
                style={{ maxHeight: '140px', maxWidth: '192px' }}
              />
            </Grid>
            <Grid item xs={10} sm={10}>
              <StyledBox data-testid='vehicleInfo'>
                <Body1
                  data-testid='description'
                  bold>{`${selectedVehicle.description} (${selectedVehicle.vehicleClass})`}</Body1>
                <Caption data-testid='similarModels' color={'#0000008A'}>
                  {selectedVehicle.similarModels + ' ' + t('vehicle.similarModels')}
                </Caption>
                <Grid data-testid='vehicleSpecs' container spacing={2}>
                  <VehicleSpecGridItem data-testid='transmissionType'>
                    <img src={AutoTransmissionDarkIcon} alt={selectedVehicle.transmissionType} />
                    <VehicleSpecText>
                      {getAutoTransmissionDescription(t, selectedVehicle.transmissionType)}
                    </VehicleSpecText>
                  </VehicleSpecGridItem>
                  <VehicleSpecGridItem data-testid='passengers'>
                    <PassengerIcon fontSize={'small'} />
                    <VehicleSpecText>{selectedVehicle.passengers}</VehicleSpecText>
                  </VehicleSpecGridItem>
                  <VehicleSpecGridItem data-testid='luggageSpace'>
                    <LuggageIcon fontSize={'small'} />
                    <VehicleSpecText>{selectedVehicle.luggageSpace}</VehicleSpecText>
                  </VehicleSpecGridItem>
                </Grid>
              </StyledBox>
            </Grid>
          </StyledGridContainer>
          <StyledBox bgcolor={'#F5F5F5'}>
            <StyledGridContainer>
              <Grid item xs={9} sm={9}>
                <StyledBox data-testid='vehicleFeaturesSection'>
                  <VehicleFeatures vehicle={selectedVehicle} />
                </StyledBox>
              </Grid>
              <Grid item xs={3} sm={3}>
                <StyledBox data-testid='vehicleDistanceAllotment' style={{ textAlign: 'end' }}>
                  <Caption2 data-testid='label' bold>
                    {t('vehicle.distanceAllotment')}
                  </Caption2>
                  <Caption data-testid='textBody' color={'#000000DE'}>
                    {t(getDistanceAllotmentText(selectedVehicle))}
                  </Caption>
                </StyledBox>
              </Grid>
            </StyledGridContainer>
          </StyledBox>
        </StyledBox>
      ) : (
        <Body1 data-testid='noVehicleSelectedText' color={'#0000008A'} padding={ehiTheme.spacing(2)}>
          {t('vehicle.noVehicleHasBeenSelected')}
        </Body1>
      )}
      <ProgressOverlay inProgress={isVehicleContentLoading || isVehicleRatesLoading} />
    </Card>
  );
};
